import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import store from './store/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";

import MainLayout from "./layouts/MainLayout.jsx";



import {PrivateRoute} from './PrivateRoute';
// import CetakNota from "./layouts/addon/CetakNota.jsx";
import { GoogleOAuthProvider } from '@react-oauth/google';

import { 
  Result,
  Button
} from 'antd';

// import { initializeFirebase } from './push-notification';

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="992350297244-o5fjfrf0tc6ssha1d2kn9k73rjn7pa42.apps.googleusercontent.com">
    	<BrowserRouter>
      	<Switch>
          <Route path="/" component={MainLayout} />
      	</Switch>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById('root')
);

// initializeFirebase();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
