import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  Skeleton,
  Card,
  Radio,
  Spin,
  Select
} from "antd";
import { StarOutlined, RightOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import { faStar, faComment, faCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";

import "antd/dist/antd.css";
import "../assets/loginStyle.css";

import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from 'moment';

import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);

const configClass = {
  apiUrl: {
    data: "transaksi/api/orders/",
  },
};

class Payment extends PureComponent {
  formRef = React.createRef();
  config;
  timeoutGetQr;
  timeOutPushQr;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      msg: "",
      loading: false,
      loadingRedirect: false,
      logo: this.config.get_site_info().logo,
      
      loading: false,
      data: null,

      not_found: false,

      isValid: true,
    };
  }

  componentDidMount() {
    // this.getData(this.props.match.params.id);
    // this.loadMasterList();
    this.getData(this.props.match.params.id);
  }

  async getData(id) {
    this.setState({ loading: true });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.data + "?qr=" + id)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if(data.results.length == 0){
            this.setState({
              not_found: true
            });
          }else{
            this.setState({
              data: data.results[0],
              isValid: data.results[0].is_offline && data.results[0].paid_on == null ? true : false
            });
          }
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({ loading: false });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({ loading: false });
      });
  }


  render() {
    var total_bayar = 0;
    if (this.props.isLogin) {
      return <Redirect to="/admin/index" />;
    }

    return (
      <div className="single-content">
        <Row justify="center">
          <Col span={24} className="judul">
            Kode Pembayaran
          </Col>
          <Col span={24} className="label-petunjuk">
            <Alert 
              message={
                <div>
                  silahkan lakukan pembayaran ke petugas loket pembayaran (kasir) dengan menunjukan kode QR dibawah ini
                </div>
              }
              type="info" 
            />
          </Col>
          <QRCode 
            value={this.props.match.params.id} 
            size={300}
            className={this.state.qrValue==''?'blur-qr':''}
            // imageSettings={{
            //   src: process.env.PUBLIC_URL + "/logo.png",
            //   height: 100,
            //   width: 100,
            // }}
          />
          {/*<Col span={24} className="nama-app">
            <img src={process.env.PUBLIC_URL + "/logo_teks.png"} height={100} />
          </Col>*/}
          {(this.state.data != null &&
            <Col span={24}>
              <Row>
                <Col span={24} className="judul">{this.state.data.usaha_nama}</Col>
              </Row>
              <Row>
                <Col span={5}>Nama</Col>
                <Col span={19}>: {this.state.data.customer}</Col>
                <Col span={5}>Tgl Trans.</Col>
                <Col span={19}>: {moment(this.state.data.created_at).format("DD-MM-YYYY HH:mm")}</Col>
              </Row>
              {this.state.data.details.map((item, idx) => (
                (total_bayar += (item.harga_akhir * item.quantity)) &&
                <>

                  <Alert
                    style={{marginTop: 5}} 
                    message={
                      <Row style={{fontStyle: "italic"}}>
                        <Col span={10}>{item.produk_nama != null ? item.produk_nama : "no produk name"}</Col>
                        <Col span={7} style={{paddingLeft: 5, paddingRight: 5}}>{parseFloat(item.harga_akhir).toLocaleString('ID')+" x "+parseFloat(item.quantity)}</Col>
                        <Col span={7}>{"= "+parseFloat(item.quantity * item.harga_akhir).toLocaleString("ID")}</Col>
                      </Row>
                    }
                    type="error" 
                  />
                </>
              ))}
              <Alert
                    style={{marginTop: 5}} 
                    message={
                      <Row style={{fontStyle: "italic"}}>
                        <Col span={24}>{"Total Tagihan: Rp. "+total_bayar.toLocaleString('ID')}</Col>
                        
                        </Row>
                    }
                    type="success" 
              />
            </Col>
          )}
           <Col span={24} className="label-petunjuk">
            <Alert 
              message={
                <div>
                  silahkan lakukan pembayaran ke petugas loket pembayaran (kasir) dengan menunjukan kode QR di atas
                </div>
              }
              type="info" 
            />
          </Col>
        </Row>


        <Modal
          title="PEMBAYARAN TIDAK VALID"
          visible={this.state.isValid == false ? true : false}
          footer={[]}
          zIndex={10000}
          width={"100%"}
          height={"100vh"}
          closable={false}
          maskClosable={false}

          style={{
            top: 50,
          }}
        >
          <div style={{height: "70vh"}}>
            <Alert 
              style={{
                top: 50,
              }}
              message={
                <div>
                  Pembayaran Tidak Valid
                </div>
              }
              type="info" 
            />
            <Alert 
              style={{
                marginTop: 60,
              }}
              message={
                <div>
                  halaman ini hanya tampil jika anda belum melakukan pembayaran dan transaksi dilakukan via plesiran.co.id bukan melalui aplikasi plesiran.
                </div>
              }
              type="warning" 
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(Payment);
