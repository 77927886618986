import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  Skeleton,
  Card,
  Radio,
  Spin,
  Select,
  Tag,
  Menu,
  Popover
} from "antd";
import { 
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  StarOutlined, RightOutlined, CheckCircleOutlined, CloseCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import { faStar, faComment, faCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";

import ReactPlayer from 'react-player'

import "antd/dist/antd.css";
import "../assets/styleFe.css";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import "react-awesome-slider/dist/styles.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import ReactStars from "react-rating-stars-component";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewsTicker, { Directions } from "react-advanced-news-ticker";
import Fade from 'react-reveal/Fade';

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from 'moment';
import 'moment/locale/id'

moment.locale('id');

const configClass = {
  apiUrl: {
    promo: "web/api/artikel/?kategori=2",
  },
};

const AutoplaySlider = withAutoplay(AwesomeSlider);

class SliderPromoMobile extends PureComponent {
  formRef = React.createRef();
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      loadingArtikel: false,
      dataArtikel: null,
      errArtikel: null,
    };
  }

  componentDidMount() {
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    //  this.props.setConfig({
    //   isMoblie: true
    //  });
    // }
    
   this.getPromo();
  }

  async getPromo() {
    this.setState({
      loadingArtikel: true,
      errArtikel: null,
      dataArtikel: null,
    });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.promo)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            dataArtikel: data,
          });
        } else {
          this.setState({
            errArtikel: JSON.stringify(data),
          });
        }
        this.setState({ loadingArtikel: false });
      })
      .catch((response) => {
        console.log("gagal_get_Promo", response);
        this.setState({
          errArtikel: JSON.stringify(response),
        });
        this.setState({ loadingArtikel: false });
      });
  }

  render() {
    var settings = {
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      variableWidth: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            //initialSlide: 2
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="blok-gap-ganjil">
                <Row className="heading" style={{marginTop: 30}}>
                  <Col span={12} className="kiri">
                    Promo Plesiran
                  </Col>
                  <Col span={12} className="kanan">
                    Lihat Selengkapnya
                    <ArrowRightOutlined style={{marginLeft: 5}} />
                  </Col>
                </Row>
                <div className="papan-info">
                  {(this.state.dataArtikel != null &&
                    <Slider
                      {...settings}
                    >
                      {this.state.dataArtikel.results.map((item, idx) =>(
                        <Fade bottom>
                        <div style={{marginRight: 10}} className="slider-info">
                          <img 
                            src={item.gambar} style={{objectFit: "cover"}} 
                            onError={(e)=>{
                              e.target.onerror = null; e.target.src=process.env.PUBLIC_URL + "/no_image.png"
                            }}
                          />
                          <div className="judul">
                            {item.judul}
                          </div>
                          <div className="isi">
                            {this.config.stripTags(item.content).substring(0, 60)+" ..."}
                            <br />
                            <Link to={"/info/detail/"+item.id+"/"+this.config.getSlug(item.judul)}>
                              <button
                                className="btn-selengkapnya"
                              >
                                selengkapnya
                                <ArrowRightOutlined style={{marginLeft: 5}} />
                              </button>
                            </Link>
                          </div>
                        </div>
                        </Fade>
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(SliderPromoMobile);
