import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  Skeleton,
  Card,
  Radio,
  Spin,
  Select,
  Tag,
  Menu,
  Popover
} from "antd";
import { 
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  StarOutlined, RightOutlined, CheckCircleOutlined, CloseCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import { faStar, faComment, faCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";

import ReactPlayer from 'react-player'

import "antd/dist/antd.css";
import "../assets/styleFe.css";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import "react-awesome-slider/dist/styles.css";

import ReactStars from "react-rating-stars-component";
import Fade from 'react-reveal/Fade';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from 'moment';
import 'moment/locale/id'

import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);

moment.locale('id');

const configClass = {
  apiUrl: {
    data: "web/api/artikel/",
  },
  // title: "Term & Conditions",
};


class DetailArtikel extends PureComponent {
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      loading: false,
      data: null,
      err: null,
    };
  }

  componentDidMount() {
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    //  this.props.setConfig({
    //   isMoblie: true
    //  });
    // }
    window.scrollTo(0, 0)

    this.getData();
  }

  async getData() {
    this.setState({
      loading: true,
      err: null,
      data: null,
    });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.data + this.props.match.params.id)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            data: data,
          });
        } else {
          this.setState({
            err: JSON.stringify(data),
          });
        }
        this.setState({ loading: false });
      })
      .catch((response) => {
        console.log("gagal_get_term", response);
        this.setState({
          err: JSON.stringify(response),
        });
        this.setState({ loading: false });
      });
  }

  render() {

    return (
      <>
        
          <Helmet>
            <title>
              {this.state.data != null ? this.state.data.judul : "Detail Plesiran"} | {this.config.get_site_info().name}
            </title>
          </Helmet>
          
          
            <div className="blok-gap-netral single-content">
              <Row className="heading">

                {(this.state.loading &&
                  <Col span={12} className="kiri">
                    <Skeleton active paragraph={true} />
                  </Col>
                )}
                {(this.state.data != null &&
                  <>
                    <Col span={24} className="teks-besar">
                      {this.state.data.judul}
                    </Col>
                    <Col span={24} className="detail__date center">
                      {moment(this.state.data.created_at).format('LLLL')}
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                {(this.state.data != null &&
                  <>
                    <Col span={24} style={{textAlign: "center"}}>
                      <img 
                        width={"80%"}
                              src={this.state.data.gambar} 
                              onError={(e)=>{
                                e.target.onerror = null; e.target.src=process.env.PUBLIC_URL + "/no_image.png"
                              }}
                      />
                    </Col>
                    <Col style={{paddingTop: 30}} span={24} dangerouslySetInnerHTML={{__html: this.state.data.content}} />
                  </>
                )}
              </Row>
            </div>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(DetailArtikel);
