import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  Skeleton,
  Card,
  Radio,
  Spin,
  Select,
  Tag,
  Menu,
  Popover
} from "antd";
import { 
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  StarOutlined, RightOutlined, CheckCircleOutlined, CloseCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import { faStar, faComment, faCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";

import ReactPlayer from 'react-player'

import "antd/dist/antd.css";
import "../assets/styleFe.css";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import "react-awesome-slider/dist/styles.css";

import ReactStars from "react-rating-stars-component";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from 'moment';
import Fade from 'react-reveal/Fade';

import 'moment/locale/id'

moment.locale('id');

const configClass = {
  apiUrl: {
    slider: "web/api/slider/",
  },
};

const AutoplaySlider = withAutoplay(AwesomeSlider);

class SliderAtas extends PureComponent {
  formRef = React.createRef();
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      loadingSlider: false,
      dataSlider: null,
      errSlider: null,
    };
  }

  componentDidMount() {
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    //  this.props.setConfig({
    //   isMoblie: true
    //  });
    // }
    
    this.getSlider();
  }

  async getSlider() {
    this.setState({ 
      loadingSlider: true,
      errSlider: null,
      dataSlider: null,
    });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.slider)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            dataSlider: data,
          });
        } else {
          this.setState({
            errSlider: JSON.stringify(data)
          });
        }
        this.setState({ loadingSlider: false });
      })
      .catch((response) => {
        console.log("gagal_get_slider", response);
        this.setState({
          errSlider: JSON.stringify(response)
        });
        this.setState({ loadingSlider: false });
      });
  }

  render() {
    return (
      
        
          
            <div className="slider-beranda">
              {(this.state.loadingSlider &&
                <Skeleton 
                  className="loading-slider-atas"
                  active
                  paragraph={false}
                  title={{
                    width: "100%",
                    height: 500
                  }}
                />
              )}
            
            
              {(this.state.dataSlider != null &&
                  <Fade bottom>
                  <AutoplaySlider
                    startupScreen={
                      <div style={{color: "#fff"}}>
                      loading...
                      </div>
                    }
                    animation="cubeAnimation"
                    play={true}
                    interval={4000}
                    bullets={true}
                    // fillParent={true}
                    organicArrows={false}
                  >
                    {(this.state.dataSlider.results.map((item, idx) => (
                      <div
                        data-src={item.gambar}
                        //style={{ height: "100%" }}
                        // onClick={() => this.showDetailUsaha(item)}
                        className="label-slider"
                      >
                        {item.judul}
                      </div>
                    )))}
                  </AutoplaySlider>
                  </Fade>
              )}
            
            </div>
      
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(SliderAtas);
