import React, { PureComponent } from "react";
import { Redirect, Link, useParams, useLocation } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  Skeleton,
  Card,
  Radio,
  Spin,
  Select,
  notification,
  Tag,
} from "antd";
import {
  StarOutlined,
  RightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
  CopyOutlined,
  ReloadOutlined,
  HomeOutlined,
  ShareAltOutlined
} from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import { faStar, faComment, faCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";

import ReactPlayer from "react-player";

// import "antd/dist/antd.css";
// import "../assets/loginStyle.css";

import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";

import ReactStars from "react-rating-stars-component";
import { GoogleLogin } from "@react-oauth/google";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from "moment";
import "moment/locale/id";

import ReactGA from "react-ga";
import SliderPromo from "../components/SliderPromo";
ReactGA.pageview(window.location.pathname + window.location.search);

moment.locale("id");

const configClass = {
  apiUrl: {
    data: "mitra/api/usaha/",
    produk: "mitra/api/produk/",
    payment: "master/api/payment/",
    transaksi: "transaksi/api/offline/orders/",
    komentar: "mitra/api/komentar/produk/",
    register: "auth/api/signup/google/",
    checkout_online: "transaksi/api/transaksi/",
    cek_room: "mitra/api/usaha/room/",
  },
};

const AutoplaySlider = withAutoplay(AwesomeSlider);

class DetailProduk extends PureComponent {
  formRef = React.createRef();
  config;
  timeoutGetQr;
  timeOutPushQr;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      msg: "",
      loading: false,
      loadingRedirect: false,
      logo: this.config.get_site_info().logo,
      loadingData: false,
      data: null,

      loadingProduk: false,
      dataProduk: null,

      loadingCart: false,
      cart: [],

      modal_jenis_bayar: false,
      modal_jenis_bayar_online: false,
      modal_login: false,
      selected_payment: -1,

      dataSummary: null,
      loadingSummary: false,

      total: 0,
      total_final: 0,
      dibayar: 0,
      kembali: 0,
      diskon_persen: "",
      diskon_rp: "",

      nama_pajak: "",
      nilai_pajak: 0,

      modal_bayar: false,
      errBayar: null,

      metode_pembayaran: "kasir",

      loadingPayment: false,
      listPayment: [],

      loadingBayar: false,

      kodeBayar: "",
      suksesBayar: null,

      loadingKomentar: false,
      loadingNextKomentar: false,
      nextKomentar: null,
      listKomentar: [],

      modal_detail: false,
      detail_produk: null,
      focus_gambar: null,

      modal_detail_usaha: false,
      detail_gambar: null,
      focus_gambar_usaha: null,

      email_kirim: "",
      loadingRefresh: false,

      detail_instruction: null,
      modal_instruction: false,

      payment_code: "virtual_account",
      modal_tunggu: false,

      referral: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.getData(this.props.match.params.id);
    if (Cookies.get(this.config.get_cookie_name())) {
      this.loadMasterList();
    }

    console.log(this.props.search)
    const referral = new URLSearchParams(this.props.search.search).get("referral");
    // alert(JSON.stringify(referral));
    this.setState({
      referral: referral != undefined && referral != null && referral != "" ? referral : null
    })
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.isLogin) != JSON.stringify(this.props.isLogin)
    ) {
      if (this.props.isLogin && Cookies.get(this.config.get_cookie_name())) {
        this.loadMasterList();
      }
    }
  }

  setEmail = (e) => {
    this.setState({
      email_kirim: e.target.value,
    });
  };

  reset() {
    this.setState({
      cart: [],
      total: 0,
      total_final: 0,
      dibayar: 0,
      kembali: 0,
      diskon_persen: "",
      diskon_rp: "",

      nama_pajak: "",
      nilai_pajak: 0,


      //
      msg: "",

      // modal_jenis_bayar: false,
      // modal_jenis_bayar_online: false,
      // modal_login: false,
      selected_payment: -1,

      dataSummary: null,

      // modal_bayar: false,
      errBayar: null,

      metode_pembayaran: "kasir",

      detail_instruction: null,
      modal_instruction: false,

    });
    if (this.formRef.current != undefined) {
      this.formRef.current.setFieldsValue({
        nama: undefined,
        email: undefined,
        no_hp: undefined,
      });
    }
  }

  loadMasterList() {
    const rule = [
      {
        destination: {
          stateProgressName: "loadingPayment",
          endPoint: configClass.apiUrl.payment,
          stateDestination: "listPayment",
        },
        nextDestination: null,
      },
    ];

    this.setState({
      errComponent: false,
    });

    rule.map((item, index) => {
      if (index == rule.length - 1) {
        this.getMasterList(item, true);
      } else {
        this.getMasterList(item);
      }
    });
  }

  async getMasterList(rule, isFinal = false) {
    var destination = rule.destination;
    var nextDestination = rule.nextDestination;
    this.setState({ [destination.stateProgressName]: true });
    this.props
      .ajaxViewHandler(
        "get",
        destination.endPoint + "?limit=10000",
        null,
        "login"
      )
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (this.state[destination.stateDestination].length == 0) {
              newState = data;
            } else {
              if (destination.stateProgressName.includes("_searching")) {
                newState = data;
              } else {
                const oldState = this.state[destination.stateDestination];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            this.setState({
              [destination.stateDestination]: newState,
            });
          } else if (data.results != undefined) {
            this.setState({
              [destination.stateDestination]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                [destination.stateDestination]: data,
              });
            } else {
              this.setState({
                [destination.stateDestination]: [data],
              });
            }
          }
          if (isFinal) {
            if (this.props.match.params.id != undefined) {
              // this.preview(this.props.match.params.id);
            }
          }
        } else {
          this.setState({
            errComponent: true,
          });
        }
        this.setState({ [destination.stateProgressName]: false });
      })
      .catch((response) => {
        //console.log('gagal_get_master', response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          errTitle: "gagal request data master",
          errMsg: response,
        });
        this.setState({ [destination.stateProgressName]: false });
      });
    //next function
    if (nextDestination != null) {
      const next = {
        destination: {
          stateProgressName: nextDestination.stateProgressName,
          endPoint: nextDestination.endPoint,
          stateDestination: nextDestination.stateDestination,
        },
        nextDestination: nextDestination.next,
      };
      this.getMasterList(next);
    }
  }

  async postDataLogin(datas) {
    this.setState({
      loading: true,
    });

    this.props
      .ajaxHandler("post", configClass.apiUrl.register, datas, false, true)
      .then(() => {
        this.setState({
          loading: false,
        });
        const response = this.props.responseMsg;
        if (this.props.isSuccess) {
          // alert(JSON.stringify(response))
          Cookies.set(
            this.config.get_cookie_name(),
            "Bearer " + response.access,
            { expires: 1 }
          );
          this.props.setConfig({
            isLogin: true,
          });
          this.setState(
            {
              loading: false,
              isLogin: true,
              modal_login: false,
              modal_jenis_bayar_online: true,
              modal_jenis_bayar: false,
            },
            () => {
              this.loadMasterList();
            }
          );
        } else {
          notification.error({
            message: `autentikasi gagal`,
            description: JSON.stringify(response),
            palcement: "bottomRight",
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post", response);
        alert(JSON.stringify(this.props.responseMsg));
      });
  }

  generateSelect(stateName, url, key, value) {
    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        showSearch
        placeholder="pilih"
        optionFilterProp="children"
        onSearch={(val) => {
          const rule = {
            destination: {
              stateProgressName: "loading" + stateName + "_searching",
              endPoint: url + "?limit=10000&search=" + val,
              stateDestination: "list" + stateName,
            },
            nextDestination: null,
          };
          this.getMasterList(rule);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
        allowClear
      >
        {this.state["loading_" + stateName + "searching"] && (
          <Select.Option value="">
            <Spin size="small" />
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
            <Select.Option value={item[key]}>
              {item[key] + " - " + item[value] + ""}
            </Select.Option>
          ))
          : this.state["list" + stateName].map((item) => (
            <Select.Option value={item[key]}>
              {item[key] + " - " + item[value] + ""}
            </Select.Option>
          ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" />
            </Select.Option>
          )}
      </Select>
    );
  }

  async cekRooms() {
    this.setState({ loadingProduk: true });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.cek_room)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            dataTunggu: data
          })
          if (data.is_available) {
            this.setState({
              modal_tunggu: false,
            });
          } else {
            this.setState({
              modal_tunggu: true,
            });
          }
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({ loadingProduk: false });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({ loadingData: false });
      });
  }

  async getData(id) {
    this.setState({ loadingData: true });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.data + "?slug=" + id)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            data: data.results[0],
          }, () => {
            const idUsaha = data.results[0].id;
            this.getDataProduk(idUsaha);
            this.getDataKomentar(idUsaha);
            this.cekRooms();
          });
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({ loadingData: false });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({ loadingData: false });
      });
  }

  async getDataProduk(id) {
    this.setState({ loadingProduk: true });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler(
        "get",
        configClass.apiUrl.produk + "?usaha=" + id + "&limit=1000&offset=0"
      )
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            dataProduk: data,
          });
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({ loadingProduk: false });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({ loadingProduk: false });
      });
  }

  async getDataKomentar(id, next = false) {
    const url =
      next == true
        ? this.state.nextKomentar
        : configClass.apiUrl.komentar + "?usaha=" + id + "&limit=5&offset=0";
    this.setState({
      loadingKomentar: next == false ? true : false,
      loadingNextKomentar: next == true ? true : false,
    });

    this.props
      .ajaxViewHandler("get", url)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (this.state.listKomentar.length == 0) {
            console.log("temp1", data.results);
            this.setState({
              listKomentar: data.results,
              nextKomentar: data.next,
            });
          } else {
            var dataTemp = this.state.listKomentar;
            dataTemp = [...dataTemp, ...data.results];
            console.log("temp", dataTemp);
            this.setState({
              listKomentar: dataTemp,
              nextKomentar: data.next,
            });
          }
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        // this.setState({ loadingKomentar: false });
        this.setState({
          loadingKomentar: false,
          loadingNextKomentar: false,
        });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          loadingKomentar: false,
          loadingNextKomentar: false,
        });
      });
  }

  addToCart(dataProduk) {
    let temp_cart = this.state.cart;
    let that = this;
    if (temp_cart.length == 0) {
      temp_cart.push({
        id: dataProduk.id,
        nama: dataProduk.nama,
        harga: dataProduk.harga_akhir,
        qty: 1,
        gambar:
          dataProduk.gambar.length > 0 && dataProduk.gambar[0].url != null
            ? dataProduk.gambar[0].url
            : process.env.PUBLIC_URL + "/no_image.png",
      });
    } else {
      const index_cari = temp_cart.findIndex(
        (data) => data.id == dataProduk.id
      );
      if (index_cari == -1) {
        temp_cart.push({
          id: dataProduk.id,
          nama: dataProduk.nama,
          harga: dataProduk.harga_akhir,
          qty: 1,
          gambar:
            dataProduk.gambar.length > 0 && dataProduk.gambar[0].url != null
              ? dataProduk.gambar[0].url
              : process.env.PUBLIC_URL + "/no_image.png",
        });
      } else {
        if (temp_cart[index_cari]["qty"] + 1 <= 5) {
          temp_cart[index_cari]["qty"] = temp_cart[index_cari]["qty"] + 1;
        } else {
          temp_cart[index_cari]["qty"] = 5;
          // notification.warning({
          //   message: 'maksimal 5',
          //   description:
          //     'maaf anda maksimal hanya bisa membeli 5 item per transaksi.',
          //   placement: "topRight",
          //   getContainer: (triggerNode) => {
          //     return document.getElementById('menu-utama');
          //   }
          // });
        }
      }
    }
    this.setState({
      cart: temp_cart,
    });
    var temp_total = 0;
    this.state.cart.map((item) => {
      temp_total += item["harga"] * item["qty"];
    });
    this.setState({
      total: temp_total,
      total_final: temp_total,
    });
  }

  setQty(e, item) {
    const regex = /^[0-9\b]+$/;
    if (regex.test(e.target.value)) {
      //nomer valid
      if (parseFloat(e.target.value) <= 5) {
        const id = item.id;
        const temp_cart = this.state.cart;
        const index_cari = temp_cart.findIndex((data) => data.id == id);
        if (index_cari != -1) {
          if (parseFloat(e.target.value) <= 0) {
            temp_cart.splice(index_cari, 1);
          } else {
            temp_cart[index_cari]["qty"] = e.target.value;
          }
        } else {
          let that = this;
          const dataProduk = item;
          if (temp_cart.length == 0) {
            temp_cart.push({
              id: dataProduk.id,
              nama: dataProduk.nama,
              harga: dataProduk.harga_akhir,
              qty: 1,
              gambar:
                dataProduk.gambar.length > 0 && dataProduk.gambar[0].url != null
                  ? dataProduk.gambar[0].url
                  : process.env.PUBLIC_URL + "/no_image.png",
            });
          } else {
            const index_cari = temp_cart.findIndex(
              (data) => data.id == dataProduk.id
            );
            if (index_cari == -1) {
              temp_cart.push({
                id: dataProduk.id,
                nama: dataProduk.nama,
                harga: dataProduk.harga_akhir,
                qty: 1,
                gambar:
                  dataProduk.gambar.length > 0 &&
                    dataProduk.gambar[0].url != null
                    ? dataProduk.gambar[0].url
                    : process.env.PUBLIC_URL + "/no_image.png",
              });
            } else {
              temp_cart[index_cari]["qty"] = temp_cart[index_cari]["qty"] + 1;
            }
          }
        }
        this.setState({
          cart: temp_cart,
        });
        var temp_total = 0;
        this.state.cart.map((item) => {
          // temp_total += (item['harga']*item['qty']);
          temp_total += item["harga"] * item["qty"];
        });
        this.setState({
          total: temp_total,
          total_final: temp_total,
        });
      } else {
        // alert('lebih')
      }
    }
  }

  kurangi(id) {
    const temp_cart = this.state.cart;
    const index_cari = temp_cart.findIndex((data) => data.id == id);
    if (index_cari != -1) {
      if (temp_cart[index_cari]["qty"] <= 1) {
        //hapus item
        temp_cart.splice(index_cari, 1);
      } else {
        //kurangi item
        temp_cart[index_cari]["qty"] = temp_cart[index_cari]["qty"] - 1;
      }
      this.setState({
        cart: temp_cart,
      });
      var temp_total = 0;
      this.state.cart.map((item) => {
        temp_total += item["harga"] * item["qty"];
      });
      this.setState({
        total: temp_total,
        total_final: temp_total,
      });
    }
  }

  pilihMetodeBayar = (e) => {
    this.setState({
      metode_pembayaran: e.target.value,
    });
  };

  handleSubmit = (values) => {
    var detail = [];
    this.state.cart.map((item) => {
      // temp_total += item["harga"] * item["qty"];
      detail = [
        ...detail,
        {
          produk: item.id,
          quantity: item.qty,
        },
      ];
    });
    var params = {
      hp: values.no_hp,
      customer: values.nama,
      email: values.email,
      usaha: this.state.data.id,
      details: detail,
    };

    if (this.state.referral != null) {
      params = { ...params, referral: this.state.referral }
    }

    this.postData(params);
  };

  async postData(datas) {
    this.setState({
      loadingBayar: true,
      suksesBayar: null,
    });

    this.props
      .ajaxHandler("post", configClass.apiUrl.transaksi, datas, false, true)
      .then(() => {
        this.setState({
          loadingBayar: false,
        });
        if (this.props.isSuccess) {
          this.setState(
            {
              suksesBayar: true,
            },
            () => {
              this.reset();
            }
          );
        } else {
          this.setState({
            suksesBayar: false,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post", response);
        this.setState({
          loadingBayar: false,
          suksesBayar: false,
        });
      });
  }

  showDetail(detail) {
    this.setState({
      focus_gambar: null,
      modal_detail: true,
      detail_produk: detail,
    });
  }

  setFocusGambar(url) {
    this.setState({
      focus_gambar: url,
    });
  }

  showDetailUsaha(detail) {
    const temp = this.state.data;
    this.setState({
      focus_gambar_usaha: detail.url,
      modal_detail_usaha: true,
      detail_gambar: temp,
    });
  }

  setFocusGambarUsaha(url) {
    this.setState({
      focus_gambar_usaha: url,
    });
  }

  handlePilihPayment(id) {
    const payments = id.split("-");
    const payment_id = payments[0];
    const payment_code = payments[1];
    this.setState(
      {
        selected_payment: payment_id,
        payment_code: payment_code,
      },
      () => {
        this.recalculate();
      }
    );
  }

  handleSubmitOnline = (values) => {
    var detail = [];
    this.state.cart.map((item) => {
      // temp_total += item["harga"] * item["qty"];
      detail = [
        ...detail,
        {
          produk: item.id,
          quantity: item.qty,
        },
      ];
    });
    var params = {
      payment_type: this.state.selected_payment,
      details: detail,
    };

    if (this.state.referral != null) {
      params = { ...params, referral: this.state.referral }
    }

    this.postDataOnline(params);
  };

  async postDataOnline(datas) {
    this.setState({
      loadingBayar: true,
      suksesBayar: null,
    });

    this.props
      .ajaxHandler(
        "post",
        configClass.apiUrl.checkout_online,
        datas,
        false,
        true,
        "login"
      )
      .then(() => {
        this.setState({
          loadingBayar: false,
        });
        if (this.props.isSuccess) {
          this.setState(
            {
              suksesBayar: true,
            },
            () => {
              console.log(this.props.responseMsg);
              this.showInstruction(this.props.responseMsg);
            }
          );
        } else {
          console.log("gagal_checkcout", this.props.responseMsg);
          this.setState(
            {
              suksesBayar: false,
            },
            () => {
              Modal.error({
                title: "Chekout Gagal",
                content: "maaf checkout transaksi gagal",
                zIndex: 10000,
              });
            }
          );
        }
      })
      .catch((response) => {
        console.log("gagal_checkcout", response);
        this.setState(
          {
            loadingBayar: false,
            suksesBayar: false,
          },
          () => {
            Modal.error({
              title: "Chekout Gagal",
              content: "maaf checkout transaksi gagal",
              zIndex: 10000,
            });
          }
        );
      });
  }

  async recalculate() {
    this.setState({ loadingSummary: true });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler(
        "get",
        configClass.apiUrl.payment +
        "?total=" +
        this.state.total +
        "&choice=" +
        this.state.selected_payment,
        null,
        "login"
      )
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            dataSummary: data.data,
          });
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({ loadingSummary: false });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({ loadingSummary: false });
      });
  }

  showInstruction(data) {
    if (this.state.payment_code == "wallet_account") {
      // this.setState({
      //   modal_instruction: false,
      //   suksesBayar: null,
      //   modal_jenis_bayar: false,
      //   selected_payment: -1,
      // }, () => {
      //   this.reset();
      //   window.location.replace(data.payment_log.payment_url);
      // });
      // this.setState({
      //   detail_instruction: data,
      //   selected_payment: -1,
      //   // modal_jenis_bayar_online: false,
      //   // modal_instruction: true,
      // });
      // // window.open(data.payment_log.payment_url, "", "width=300,height=550");
      // window.location.replace(data.payment_log.payment_url);
      /*
      set all default same payment
      */
      this.setState({
        detail_instruction: data,
        modal_jenis_bayar_online: false,
        modal_instruction: true,
      });
    } else {
      this.setState({
        detail_instruction: data,
        modal_jenis_bayar_online: false,
        modal_instruction: true,
      });
    }
  }

  async refreshStatus(id) {
    this.setState({ loadingRefresh: true });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler(
        "get",
        configClass.apiUrl.checkout_online + id + "/",
        null,
        "login"
      )
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            detail_instruction: data,
          });
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({ loadingRefresh: false });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({ loadingRefresh: false });
      });
  }

  render() {
    if (this.props.suksesBayar == true) {
      return <Redirect to={"/payment/" + this.state.kodeBayar} />;
    }

    return (
      <div>
        {this.state.data != null && (
          <Helmet>
            <title>
              {this.state.data.nama} | {this.config.get_site_info().name}
            </title>
          </Helmet>
        )}
        {this.state.data != null && (
          <Row className="detail-page">
            <Col xs={24} md={10}>
              <div className="slider-detail">
                <AutoplaySlider
                  startupScreen={<div style={{ color: "#fff" }}>loading...</div>}
                  animation="scaleOutAnimation"
                  play={true}
                  interval={4000}
                  // style={{ height: 200 }}
                  // className="slider-beranda"
                  bullets={true}
                  organicArrows={false}
                >
                  {this.state.data.gambar.map((item, idx) => (
                    <div
                      data-src={item.url}
                      // style={{ height: 220 }}
                      onClick={() => this.showDetailUsaha(item)}
                      className="label-slider"
                      style={{
                        objectFit: "cover",
                      }}
                    >
                      {this.state.data.is_available == false && (
                        <div class="not-avaliable-usaha">
                          <WarningOutlined /> Tiket Belum Bisa Dibeli
                        </div>
                      )}
                    </div>
                  ))}
                </AutoplaySlider>
              </div>
              <div className="panel-gbr-list">
                {this.state.data.gambar.map((item, idx) => (
                  <div className="item-gbr-list">
                    <img
                      src={item.url}
                      onClick={() => this.showDetailUsaha(item)}
                    />
                  </div>
                ))}
              </div>
              <div className="panel-ul-kom-suk card-panel">
                <div class="garis-panel-detail"></div>
                <Row>
                  <Col
                    span={6}
                    className="panel-rating"
                    style={{ textAlign: "center" }}
                  >
                    {this.state.loadingData == true ? (
                      <Skeleton rows={2} paragraph={false} active={true} />
                    ) : (
                      this.state.data != null && (
                        <ReactStars
                          size={"8px"}
                          className="rating"
                          style={{ height: 22 }}
                          edit={false}
                          isHalf={true}
                          value={this.state.data.rating}
                          count={5}
                          // onChange={ratingChanged}
                          activeColor="#ffd700"
                        />
                      )
                    )}
                    <div className="jml-rating">
                      {this.state.loadingData == true ? (
                        <Skeleton rows={2} paragraph={false} active={true} />
                      ) : (
                        this.state.data != null &&
                        "(" + this.state.data.rating + ")"
                      )}
                      <br />
                      <span style={{ fontWeight: "normal" }}>Rating</span>
                    </div>
                  </Col>
                  <Col
                    span={6}
                    className="panel-komentar"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/dilihat.svg"}
                      style={{ height: 35, marginBottom: 2 }}
                    />
                    <div className="avg-rating">
                      {this.state.loadingData == true ? (
                        <Skeleton rows={2} paragraph={false} active={true} />
                      ) : (
                        this.state.data != null && this.state.data.page_views
                      )}
                    </div>
                    <div className="jml-rating">
                      <span style={{ fontWeight: "normal" }}>Dilihat</span>
                    </div>
                  </Col>
                  <Col
                    span={6}
                    className="panel-komentar"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/like.svg"}
                      style={{ height: 28, marginBottom: 10 }}
                    />
                    <div className="avg-rating">
                      {this.state.loadingData == true ? (
                        <Skeleton rows={2} paragraph={false} active={true} />
                      ) : (
                        this.state.data != null && this.state.data.count_like
                      )}
                    </div>
                    <div className="jml-rating">
                      <span style={{ fontWeight: "normal" }}>Disukai</span>
                    </div>
                  </Col>
                  <Col
                    span={6}
                    className="panel-like"
                    style={{ textAlign: "center" }}
                  >
                    <CopyToClipboard
                      text={
                        this.state.data != null && this.state.data.kode
                      }
                      onCopy={() => {
                        Modal.success({
                          content: "berhasil menyalin link",
                          zIndex: 10000,
                        });
                      }}
                    >
                      <ShareAltOutlined
                        style={{
                          fontSize: 35,
                          color: "#1BE23B"
                        }}
                      />
                    </CopyToClipboard>
                    <div className="avg-rating">
                      -
                    </div>
                    <div className="jml-rating">
                      <span style={{ fontWeight: "normal" }}>Share</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={24} md={14} className="kanan-detail">
              <div className="card-panels">
                <div class="garis-panels"></div>
                <Row className="panel-detail">
                  <Col span={12} className="nama-produk">
                    {this.state.loadingData == true ? (
                      <Skeleton rows={2} paragraph={false} active={true} />
                    ) : (
                      this.state.data != null && this.state.data.nama
                    )}
                  </Col>
                  <Col span={12} className="panel-video">
                    {this.state.loadingData == true ? (
                      <Skeleton rows={2} paragraph={false} active={true} />
                    ) : (
                      this.state.data != null && (
                        <a href={this.state.data.video.url} target="_blank">
                          <img
                            src={process.env.PUBLIC_URL + "/youtube.png"}
                            height="30"
                          />
                          <span className="label-video">
                            {this.state.data.video.kategori}
                          </span>
                        </a>
                      )
                    )}
                  </Col>

                  <Col span={24} className="panel-vaksin">
                    {/* {this.state.loadingData == true ? (
                      <Skeleton rows={2} paragraph={false} active={true} />
                    ) : (
                      this.state.data != null &&
                      this.state.data.is_vaksin && (
                        <Row justify="start">
                          <Col>
                            <img
                              src={process.env.PUBLIC_URL + "/vaksin.svg"}
                              align="left"
                              style={{ marginRight: 5, height: 18 }}
                            />
                          </Col>
                          <Col>Staff kami telah divaksinasi untuk COVID-19</Col>
                        </Row>
                      )
                    )} */}

                    {this.state.loadingData == true ? (
                      <Skeleton rows={2} paragraph={false} active={true} />
                    ) : (
                      this.state.data != null && this.state.data.jenis != 7 && (
                        <Row justify="start" style={{ marginTop: 10 }}>
                          <Col>
                            <img
                              src={process.env.PUBLIC_URL + "/clock.svg"}
                              style={{ height: 18, marginRight: 5 }}
                              align="left"
                            />
                          </Col>
                          <Col>
                            {Object.keys(this.state.data.operasional).map(
                              (item, key) => (
                                <div>
                                  {item + " : " + this.state.data.operasional[item]}
                                </div>
                              )
                            )}
                          </Col>
                        </Row>
                      )
                    )}
                  </Col>
                  <Col span={24} className="panel-detail-bawah">
                    <Tabs type="card" style={{ marginTop: 20 }}>
                      <Tabs.TabPane tab="Informasi" key="deskripsi">
                        {this.state.loadingData == true ? (
                          <Skeleton active={true} />
                        ) : (
                          this.state.data != null && (
                            <div
                              align="justify"
                              dangerouslySetInnerHTML={{
                                __html: this.state.data.deskripsi.replace(
                                  /\./g,
                                  "<br />"
                                ),
                              }}
                            />
                          )
                        )}
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab={
                          <>
                            {this.state.data != null &&
                              this.state.data.jenis == 1 &&
                              "Tiket/ Produk"}
                            {this.state.data != null &&
                              this.state.data.jenis == 2 &&
                              "Tiket/ Produk"}
                            {this.state.data != null &&
                              this.state.data.jenis == 3 &&
                              "Pilih Menu"}
                            {this.state.data != null &&
                              this.state.data.jenis == 4 &&
                              "Tiket/ Produk"}
                            {this.state.data != null &&
                              this.state.data.jenis == 5 &&
                              "Tiket/ Produk"}
                            {this.state.data != null &&
                              this.state.data.jenis == 6 &&
                              "Tiket/ Produk"}
                            {this.state.data != null &&
                              this.state.data.jenis == 7 &&
                              "Tiket"}
                          </>
                        }
                        key="produk"
                      >
                        {this.state.loadingProduk == true ? (
                          <Skeleton avatar paragraph={{ rows: 3 }} />
                        ) : (
                          this.state.dataProduk != null &&
                          (this.state.dataProduk.results.length == 0 ? (
                            <div>
                              <WarningOutlined /> belum ada data
                              {this.state.data != null &&
                                this.state.data.jenis == 1 &&
                                " Tiket & Wahana"}
                              {this.state.data != null &&
                                this.state.data.jenis == 2 &&
                                " Tiket"}
                              {this.state.data != null &&
                                this.state.data.jenis == 3 &&
                                " Menu"}
                              {this.state.data != null &&
                                this.state.data.jenis == 4 &&
                                " Paket Wisata"}
                              {this.state.data != null &&
                                this.state.data.jenis == 5 &&
                                " Kamar/Ruangan"}
                              {this.state.data != null &&
                                this.state.data.jenis == 6 &&
                                " Produk"}
                              {this.state.data != null &&
                                this.state.data.jenis == 7 &&
                                " Tiket"}
                            </div>
                          ) : (
                            this.state.dataProduk.results.map(
                              (item, idx) =>
                                this.state.data != null &&
                                this.state.data.is_available == true &&
                                item.is_available == true && (
                                  <Row className="produk-list">
                                    <Col
                                      span={4}
                                      onClick={() => this.showDetail(item)}
                                      style={{ padding: 3 }}
                                    >
                                      <img
                                        src={
                                          item.gambar.length == 0
                                            ? process.env.PUBLIC_URL + "/no_image.png"
                                            : item.gambar[0].url
                                        }
                                        width={"100%"}
                                      />
                                    </Col>
                                    <Col
                                      span={13}
                                      style={{ padding: 3 }}
                                      onClick={() => this.showDetail(item)}
                                    >
                                      <div style={{ fontWeight: "bold" }}>
                                        {item.nama}{" "}
                                        <InfoCircleOutlined
                                          title={"klik untuk informasi"}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          color: "#1BA0E2",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {"max 5 item"}
                                      </div>
                                      {item.harga_awal > item.harga_akhir ? (
                                        <div>
                                          <del>
                                            {"Rp. " +
                                              item.harga_awal.toLocaleString("ID")}
                                          </del>
                                          <br />
                                          {"Rp. " +
                                            item.harga_akhir.toLocaleString("ID")}
                                        </div>
                                      ) : (
                                        <div>
                                          {"Rp. " +
                                            item.harga_akhir.toLocaleString("ID")}
                                        </div>
                                      )}
                                    </Col>
                                    <Col
                                      span={7}
                                      style={{ textAlign: "right", padding: 3 }}
                                    >
                                      {this.state.data != null &&
                                        this.state.data.is_available == true &&
                                        item.is_available == true ? (
                                        <>
                                          <div className="sub-total">
                                            {this.state.cart.length > 0 &&
                                              this.state.cart.findIndex(
                                                (data) => data.id == item.id
                                              ) != -1
                                              ? "Rp. " +
                                              (
                                                this.state.cart[
                                                  this.state.cart.findIndex(
                                                    (data) => data.id == item.id
                                                  )
                                                ].qty *
                                                this.state.cart[
                                                  this.state.cart.findIndex(
                                                    (data) => data.id == item.id
                                                  )
                                                ].harga
                                              ).toLocaleString("ID")
                                              : "Rp. 0"}
                                          </div>
                                          <Row>
                                            <Col span={6}>
                                              <button
                                                className="btn-kurangi"
                                                onClick={() => this.kurangi(item.id)}
                                              >
                                                -
                                              </button>
                                            </Col>
                                            <Col span={12}>
                                              <input
                                                value={
                                                  this.state.cart.length > 0 &&
                                                    this.state.cart.findIndex(
                                                      (data) => data.id == item.id
                                                    ) != -1
                                                    ? this.state.cart[
                                                      this.state.cart.findIndex(
                                                        (data) => data.id == item.id
                                                      )
                                                    ].qty
                                                    : "0"
                                                }
                                                className="qty"
                                                onChange={(e) => this.setQty(e, item)}
                                              />
                                            </Col>
                                            <Col span={6}>
                                              <button
                                                className="btn-tambah"
                                                onClick={() => this.addToCart(item)}
                                              >
                                                +
                                              </button>
                                            </Col>
                                          </Row>
                                        </>
                                      ) : (
                                        <div className="sub-total">
                                          <div className="not-avaliable-produk">
                                            <WarningOutlined /> tiket belum bisa
                                            dibeli
                                          </div>
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                )
                            )
                          ))
                        )}
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab={this.state.loadingKomentar ? "Ulasan" : "Ulasan"}
                        key="komentar"
                      >
                        {this.state.loadingKomentar ? (
                          <Skeleton avatar paragraph={{ rows: 2 }} />
                        ) : this.state.listKomentar.length == 0 ? (
                          <div>
                            <WarningOutlined /> belum ada ulasan
                          </div>
                        ) : (
                          <>
                            {this.state.listKomentar.map((item, idx) => (
                              <Row className="komen-garis">
                                <Col style={{ padding: 5 }}>
                                  <div className="komen-avatar">
                                    {item.user_nama != null
                                      ? item.user_nama.substring(0, 1).toUpperCase()
                                      : "0"}
                                  </div>
                                </Col>
                                <Col flex="auto" style={{ padding: 5 }}>
                                  <Row justify="space-between">
                                    <Col>
                                      <div className="komen-nama">
                                        {item.user_nama != null
                                          ? item.user_nama
                                          : "no name"}
                                      </div>
                                      <div className="komen-tanggal">
                                        {moment(item.created_at).fromNow()}
                                      </div>
                                    </Col>
                                    <Col style={{ textAlign: "right" }}>
                                      <div className="komen-rating">
                                        <img
                                          src={process.env.PUBLIC_URL + "/star.svg"}
                                          style={{ height: 14 }}
                                        />
                                        {item.rating}
                                      </div>
                                      <div className="komen-tgl">
                                        {moment(item.created_at).format(
                                          "DD-MM-YYYY HH:mm"
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={24} className="komen-isi">
                                      <div className="komen-produk">
                                        <img
                                          src={
                                            item.produk_gambar == null
                                              ? process.env.PUBLIC_URL +
                                              "/no_image.png"
                                              : item.produk_gambar
                                          }
                                          height={20}
                                          align="left"
                                          style={{ marginRight: 5 }}
                                        />
                                        {item.produk_nama}
                                      </div>
                                      <div className="komen-isi">{item.komentar}</div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            ))}
                            {this.state.loadingNextKomentar == true && (
                              <Skeleton avatar paragraph={{ rows: 2 }} />
                            )}
                            {this.state.nextKomentar != null && (
                              <div style={{ textAlign: "center" }}>
                                <button
                                  className="btn-komen-selengkapnya"
                                  onClick={() => {
                                    this.getDataKomentar(
                                      this.state.data.id,
                                      true
                                    );
                                  }}
                                >
                                  Ulasan Selanjutnya
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab={
                          this.state.loadingKomentar
                            ?
                            ""
                            :
                            <>
                              <ShareAltOutlined />
                              Share
                            </>
                        }
                        key="share"
                      >
                        {this.state.data != null &&
                          <>
                            {this.state.data.kode}
                            <br />
                          </>
                        }
                        <CopyToClipboard
                          text={
                            this.state.data != null && this.state.data.kode
                          }
                          onCopy={() => {
                            Modal.success({
                              content: "berhasil menyalin link",
                              zIndex: 10000,
                            });
                          }}
                        >
                          <Button
                            type="primary"
                            htmlType="button"
                            style={{
                              background: "#1BA0E2",
                              borderRadius: 10
                            }}
                          >
                            <CopyOutlined />
                            Salin Link
                          </Button>
                        </CopyToClipboard>
                      </Tabs.TabPane>
                    </Tabs>
                  </Col>
                  {/*<Col span={24} className="label-produk">
              DAFTAR LIST PRODUK
            </Col>
            <Col span={24}>
              {this.state.loadingProduk == true ? (
                <Skeleton rows={2} />
              ) : (
                this.state.dataProduk != null &&
                this.state.dataProduk.results.map((item, idx) => (
                  <Row className="produk-list">
                    <Col span={4}>
                      <img src={item.gambar.length == 0 ? process.env.PUBLIC_URL + "/no_image.png" : item.gambar[0].url} height={50} />
                    </Col>
                    <Col span={13} style={{ paddingLeft: 10 }}>
                      <div style={{ fontWeight: "bold" }}>{item.nama}</div>
                      {item.harga_awal > 0 ? (
                        <div>
                          <del>
                            {"Rp. " + item.harga_awal.toLocaleString("ID")}
                          </del>
                          <br />
                          {"Rp. " + item.harga_akhir.toLocaleString("ID")}
                        </div>
                      ) : (
                        <div>
                          {"Rp. " + item.harga_akhir.toLocaleString("ID")}
                        </div>
                      )}
                    </Col>
                    <Col span={7} style={{ textAlign: "right" }}>
                      <div className="sub-total">
                        {this.state.cart.length > 0 &&
                        this.state.cart.findIndex(
                          (data) => data.id == item.id
                        ) != -1
                          ? "Rp. " +
                            (
                              this.state.cart[
                                this.state.cart.findIndex(
                                  (data) => data.id == item.id
                                )
                              ].qty *
                              this.state.cart[
                                this.state.cart.findIndex(
                                  (data) => data.id == item.id
                                )
                              ].harga
                            ).toLocaleString("ID")
                          : "Rp. 0"}
                      </div>
                      <Row>
                        <Col span={6}>
                          <button
                            className="btn-kurangi"
                            onClick={() => this.kurangi(item.id)}
                          >
                            -
                          </button>
                        </Col>
                        <Col span={12}>
                          <input
                            value={
                              this.state.cart.length > 0 &&
                              this.state.cart.findIndex(
                                (data) => data.id == item.id
                              ) != -1
                                ? this.state.cart[
                                    this.state.cart.findIndex(
                                      (data) => data.id == item.id
                                    )
                                  ].qty
                                : "0"
                            }
                            className="qty"
                          />
                        </Col>
                        <Col span={6}>
                          <button
                            className="btn-tambah"
                            onClick={() => this.addToCart(item)}
                          >
                            +
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))
              )}
            </Col>*/}
                  {this.state.cart.length > 0 && (
                    <button
                      className="btn-beli"
                      onClick={() => {
                        // this.setState({modal_bayar: true})
                        this.setState({ modal_jenis_bayar: true });
                      }}
                    >
                      <Row>
                        <Col span={7} style={{ borderRight: "1px solid #fff" }}>
                          Bayar
                        </Col>
                        <Col span={15} className="total">
                          {"Rp. " + parseFloat(this.state.total).toLocaleString("ID")}
                        </Col>
                        <Col span={2} className="total">
                          <RightOutlined />
                        </Col>
                      </Row>
                    </button>
                  )}
                </Row>
              </div>

            </Col>
          </Row>
        )}


        <Modal
          title="PILIH METODE PEMBAYARAN"
          visible={this.state.modal_jenis_bayar}
          onCancel={() => {
            this.setState({ modal_jenis_bayar: false });
          }}
          footer={[]}
          zIndex={10000}
          style={{
            top: 100,
          }}
        >
          <Row>
            <Col span={24} style={{ marginBottom: 10 }}>
              <Button
                style={{ width: "100%", height: 50 }}
                type="primary"
                htmlType="button"
                onClick={(e) => {
                  this.setState({
                    modal_bayar: true,
                    modal_jenis_bayar: false,
                    selected_payment: -1,
                    dataSummary: null,
                  });
                }}
              >
                Bayar di Kasir
              </Button>
            </Col>
            <Col span={24} style={{ marginBottom: 10 }}>
              <Button
                style={{ width: "100%", height: 50 }}
                type="primary"
                htmlType="button"
                onClick={(e) => {
                  if (Cookies.get(this.config.get_cookie_name()) && this.props.isLogin) {
                    this.setState({
                      modal_jenis_bayar_online: true,
                      modal_jenis_bayar: false,
                      selected_payment: -1,
                      dataSummary: null,
                    });
                  } else {
                    this.setState({
                      modal_login: true,
                    });
                  }
                }}
              >
                Bayar Secara Online
              </Button>
            </Col>
          </Row>
        </Modal>
        <Modal
          title="LOGIN TERLEBIH DAHULU"
          visible={this.state.modal_login}
          onCancel={() => {
            this.setState({ modal_login: false });
          }}
          footer={[]}
          zIndex={10000}
          style={{
            top: 100,
          }}
        >
          <Row>
            <Col span={24} style={{ marginBottom: 10 }}>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  // console.log(credentialResponse);
                  var datas = {
                    token: credentialResponse.credential,
                    level: 4,
                    tipe: 4,
                  };
                  this.postDataLogin(datas);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
                text={"signin_with"}
                theme={"outline "}
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          title="PILIH METODE PEMBAYARAN"
          visible={this.state.modal_jenis_bayar_online}
          onCancel={() => {
            this.setState({ modal_jenis_bayar_online: false });
          }}
          width={"90%"}
          footer={[]}
          zIndex={10000}
          style={{
            top: 100,
          }}
        >
          <Spin
            size="large"
            spinning={this.state.loadingBayar}
            tip={
              <span style={{ color: "#000" }}>
                sedang memproses, mohon menunggu...
              </span>
            }
          >
            <div>
              {this.props.isMobile ? (
                <Row>
                  <Col span={24}>
                    <Select
                      showSearch
                      placeholder="pilih metode pembayaran"
                      optionFilterProp="children"
                      style={{
                        width: "100%",
                      }}
                      size="large"
                      onChange={(val) => {
                        this.handlePilihPayment(val);
                      }}
                    >
                      {this.state.listPayment.results != undefined &&
                        this.state.listPayment.results.map((item, idx) => (
                          <Select.Option value={item.id + "-" + item.code}>
                            {item.nama}
                            <img
                              src={item.icon}
                              height={30}
                              style={{ float: "right" }}
                            />
                          </Select.Option>
                        ))}
                    </Select>
                  </Col>
                  <Col span={24}>
                    <Row className="panel-payment" style={{ marginTop: 10 }}>
                      <Col span={12}>Nominal Order</Col>
                      <Col span={12}>
                        {this.state.dataSummary != null
                          ? "Rp. " +
                          this.state.dataSummary.total_awal.toLocaleString(
                            "ID"
                          )
                          : "Rp. -"}
                      </Col>
                      <Col span={12}>Platform Fee</Col>
                      <Col span={12}>
                        {this.state.dataSummary != null
                          ? "Rp. " +
                          this.state.dataSummary.fee.toLocaleString("ID")
                          : "Rp. -"}
                      </Col>
                      <Col span={12}>Total</Col>
                      <Col span={12}>
                        {this.state.dataSummary != null
                          ? "Rp. " +
                          (
                            this.state.dataSummary.total_awal +
                            this.state.dataSummary.fee
                          ).toLocaleString("ID")
                          : "Rp. -"}
                      </Col>
                      <Col span={12} className="diskon">
                        Diskon
                      </Col>
                      <Col span={12} className="diskon">
                        {this.state.dataSummary != null
                          ? "Rp. " +
                          this.state.dataSummary.diskon.toLocaleString("ID")
                          : "Rp. -"}
                      </Col>
                      <Col span={12} className="total-akhir">
                        Total Akhir
                      </Col>
                      <Col span={12} className="total-akhir">
                        {this.state.dataSummary != null
                          ? "Rp. " +
                          this.state.dataSummary.total_akhir.toLocaleString(
                            "ID"
                          )
                          : "Rp. -"}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row justify="center" style={{ marginTop: 10 }}>
                      <Col style={{ padding: 5 }}>
                        <Button
                          key="btn-simpan"
                          loading={this.state.loadingSummary}
                          type="primary"
                          htmlType="submit"
                          disabled={
                            this.state.dataSummary == null ? true : false
                          }
                          size="small"
                          size="large"
                          onClick={(e) => this.handleSubmitOnline()}
                        >
                          {!this.state.loadingSummary && (
                            <CheckCircleOutlined />
                          )}
                          <>
                            {this.state.data == null && "Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 1 &&
                              " Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 2 &&
                              " Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 3 &&
                              " Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 4 &&
                              " Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 5 &&
                              " Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 6 &&
                              " Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 7 &&
                              " Checkout"}
                          </>
                        </Button>
                      </Col>
                      <Col style={{ padding: 5 }}>
                        <Button
                          type="danger"
                          key="btn-cancel"
                          disabled={this.state.loadingSummary ? true : false}
                          size="small"
                          onClick={() =>
                            this.setState({ modal_jenis_bayar_online: false })
                          }
                          size="large"
                        >
                          <CloseCircleOutlined /> Batal
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col span={12}>
                    {this.state.listPayment.results != undefined &&
                      this.state.listPayment.results.map((item, idx) => (
                        <Row
                          className={
                            this.state.selected_payment == item.id
                              ? "selected-payment"
                              : "no-selected-payment"
                          }
                        >
                          <Col span={6}>
                            <Button
                              type="text"
                              style={{ border: "none" }}
                              onClick={(e) => this.handlePilihPayment(item.id + "-" + item.code)}
                            >
                              <img src={item.icon} width={"100%"} height={30} />
                            </Button>
                          </Col>
                          <Col span={18} style={{ fontSize: 18 }}>
                            <Button
                              type="text"
                              style={{ border: "none" }}
                              onClick={(e) => this.handlePilihPayment(item.id + "-" + item.code)}
                            >
                              {item.nama}
                            </Button>
                          </Col>
                        </Row>
                      ))}
                  </Col>
                  <Col span={12} style={{ padding: 10 }}>
                    <Row className="panel-payment">
                      <Col span={12}>Nominal Order</Col>
                      <Col span={12}>
                        {this.state.dataSummary != null
                          ? "Rp. " +
                          this.state.dataSummary.total_awal.toLocaleString(
                            "ID"
                          )
                          : "Rp. -"}
                      </Col>
                      <Col span={12}>Platform Fee</Col>
                      <Col span={12}>
                        {this.state.dataSummary != null
                          ? "Rp. " +
                          this.state.dataSummary.fee.toLocaleString("ID")
                          : "Rp. -"}
                      </Col>
                      <Col span={12}>Total</Col>
                      <Col span={12}>
                        {this.state.dataSummary != null
                          ? "Rp. " +
                          (
                            this.state.dataSummary.total_awal +
                            this.state.dataSummary.fee
                          ).toLocaleString("ID")
                          : "Rp. -"}
                      </Col>
                      <Col span={12} className="diskon">
                        Diskon
                      </Col>
                      <Col span={12} className="diskon">
                        {this.state.dataSummary != null
                          ? "Rp. " +
                          this.state.dataSummary.diskon.toLocaleString("ID")
                          : "Rp. -"}
                      </Col>
                      <Col span={12} className="total-akhir">
                        Total Akhir
                      </Col>
                      <Col span={12} className="total-akhir">
                        {this.state.dataSummary != null
                          ? "Rp. " +
                          this.state.dataSummary.total_akhir.toLocaleString(
                            "ID"
                          )
                          : "Rp. -"}
                      </Col>
                    </Row>
                    <Row justify="center" style={{ marginTop: 10 }}>
                      <Col style={{ padding: 5 }}>
                        <Button
                          key="btn-simpan"
                          loading={this.state.loadingSummary}
                          type="primary"
                          htmlType="submit"
                          disabled={
                            this.state.dataSummary == null ? true : false
                          }
                          size="small"
                          size="large"
                          onClick={(e) => this.handleSubmitOnline()}
                        >
                          {!this.state.loadingSummary && (
                            <CheckCircleOutlined />
                          )}
                          <>
                            {this.state.data == null && "Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 1 &&
                              " Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 2 &&
                              " Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 3 &&
                              " Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 4 &&
                              " Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 5 &&
                              " Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 6 &&
                              " Checkout"}
                            {this.state.data != null &&
                              this.state.data.jenis == 7 &&
                              " Checkout"}
                          </>
                        </Button>
                      </Col>
                      <Col style={{ padding: 5 }}>
                        <Button
                          type="danger"
                          key="btn-cancel"
                          disabled={this.state.loadingSummary ? true : false}
                          size="small"
                          onClick={() =>
                            this.setState({ modal_jenis_bayar_online: false })
                          }
                          size="large"
                        >
                          <CloseCircleOutlined /> Batal
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </div>
          </Spin>
        </Modal>
        <Modal
          title="DETAIL GAMBAR"
          visible={this.state.modal_detail_usaha}
          onCancel={() => {
            this.setState({ modal_detail_usaha: false });
          }}
          footer={[]}
          zIndex={10000}
        >
          {this.state.detail_gambar != null && (
            <>
              {this.state.detail_gambar.gambar.length > 0 && (
                <>
                  <Row>
                    <Col span={24} style={{ textAlign: "center" }}>
                      <img
                        src={
                          this.state.focus_gambar_usaha != null
                            ? this.state.focus_gambar_usaha
                            : this.state.detail_gambar.gambar[0].url != null
                              ? this.state.detail_gambar.gambar[0].url
                              : process.env.PUBLIC_URL + "/no_image.png"
                        }
                        height={200}
                      />
                    </Col>
                  </Row>
                  <div style={{ textAlign: "center" }}>
                    {this.state.detail_gambar.gambar.map((item, idx) => (
                      <div
                        style={{ padding: 10, display: "inline-block" }}
                        onClick={() => this.setFocusGambarUsaha(item.url)}
                      >
                        <img
                          src={item.url}
                          height={50}
                          width={60}
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </Modal>
        <Modal
          title={
            <>
              {this.state.data == null && "Detail Produk"}
              {this.state.data != null &&
                this.state.data.jenis == 1 &&
                "Detail Tiket & Wahana"}
              {this.state.data != null &&
                this.state.data.jenis == 2 &&
                "Detail Tiket"}
              {this.state.data != null &&
                this.state.data.jenis == 3 &&
                "Detail Menu"}
              {this.state.data != null &&
                this.state.data.jenis == 4 &&
                " Pesan"}
              {this.state.data != null &&
                this.state.data.jenis == 5 &&
                " Pesan"}
              {this.state.data != null &&
                this.state.data.jenis == 6 &&
                " Pesan"}
              {this.state.data != null &&
                this.state.data.jenis == 7 &&
                " Pesan"}
            </>
          }
          visible={this.state.modal_detail}
          onCancel={() => {
            this.setState({ modal_detail: false });
          }}
          footer={[]}
          zIndex={10000}
        >
          {this.state.detail_produk != null && (
            <>
              {this.state.detail_produk.gambar.length > 0 && (
                <>
                  <Row>
                    <Col span={24} style={{ textAlign: "center" }}>
                      <img
                        src={
                          this.state.focus_gambar != null
                            ? this.state.focus_gambar
                            : this.state.detail_produk.gambar[0].url != null
                              ? this.state.detail_produk.gambar[0].url
                              : process.env.PUBLIC_URL + "/no_image.png"
                        }
                        height={200}
                      />
                    </Col>
                  </Row>
                  <div style={{ textAlign: "center" }}>
                    {this.state.detail_produk.gambar.map((item, idx) => (
                      <div
                        style={{ padding: 10, display: "inline-block" }}
                        onClick={() => this.setFocusGambar(item.url)}
                      >
                        <img
                          src={item.url}
                          height={50}
                          width={60}
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
              <Row>
                <Col span={24}>
                  <Tabs type="card">
                    <Tabs.TabPane
                      tab={
                        <>
                          {/*this.state.data == null && "Deskripsi Produk"*/}
                          {this.state.data != null &&
                            this.state.data.jenis == 1 &&
                            "Informasi"}
                          {this.state.data != null &&
                            this.state.data.jenis == 2 &&
                            "Informasi"}
                          {this.state.data != null &&
                            this.state.data.jenis == 3 &&
                            "Informasi"}
                          {this.state.data != null &&
                            this.state.data.jenis == 4 &&
                            "Informasi"}
                          {this.state.data != null &&
                            this.state.data.jenis == 5 &&
                            "Informasi"}
                          {this.state.data != null &&
                            this.state.data.jenis == 6 &&
                            "Informasi"}
                          {this.state.data != null &&
                            this.state.data.jenis == 7 &&
                            "Informasi"}
                        </>
                      }
                      key="deskripsi-produk"
                    >
                      <div
                        align="justify"
                        dangerouslySetInnerHTML={{
                          __html: this.state.detail_produk.deskripsi.replace(
                            /\./g,
                            "<br />"
                          ),
                        }}
                      />
                    </Tabs.TabPane>
                    {this.state.detail_produk.video != undefined &&
                      this.state.detail_produk.video != null &&
                      this.state.detail_produk.video != "" &&
                      (typeof this.state.detail_produk.video === "object" &&
                        Object.keys(this.state.detail_produk.video).length !=
                        0 ? (
                        <Tabs.TabPane
                          tab={this.state.detail_produk.video.kategori}
                          key="video-produk"
                        >
                          <ReactPlayer
                            url={this.state.detail_produk.video.url}
                            width={300}
                            height={280}
                            controls={true}
                          />
                        </Tabs.TabPane>
                      ) : (
                        typeof this.state.detail_produk.video === "string" && (
                          <Tabs.TabPane
                            tab={
                              JSON.parse(this.state.detail_produk.video)
                                .kategori
                            }
                            key="video-produk"
                          >
                            <ReactPlayer
                              url={
                                JSON.parse(this.state.detail_produk.video).url
                              }
                              width={300}
                              height={280}
                              controls={true}
                            />
                          </Tabs.TabPane>
                        )
                      ))}
                  </Tabs>
                </Col>
              </Row>
            </>
          )}
        </Modal>
        <Modal
          title="ISI BIODATA PEMESANAN BERIKUT"
          visible={this.state.modal_bayar}
          onCancel={() => {
            this.setState({ modal_bayar: false });
            if (this.state.suksesBayar == true) {
              this.setState({
                suksesBayar: null,
              });
            }
          }}
          footer={[]}
          zIndex={10000}
          style={{
            top: 50,
          }}
        >
          <Spin
            size="large"
            spinning={this.state.loadingBayar}
            tip={
              <span style={{ color: "#000" }}>
                sedang memproses, mohon menunggu...
              </span>
            }
          >
            <Form
              layout="vertical"
              name="fdata"
              onFinish={this.handleSubmit}
              size="small"
              ref={this.formRef}
              initialValues={
                {
                  // metode_pembayaran: "kasir"
                }
              }
            >
              {(this.state.suksesBayar == null ||
                this.state.suksesBayar == false) && (
                  <Alert
                    showIcon
                    closable
                    style={{
                      marginBottom: 10,
                    }}
                    message="Perhatian"
                    description={
                      <div>
                        pastikan anda memiliki akses ke email yang dimasukkan.
                        pemberitahuan selanjutnya akan dikirimkan ke email anda
                      </div>
                    }
                    type="warning"
                  />
                )}
              <Row className="isi">
                <Col lg={24} sm={24} xs={24}>
                  {this.state.loadingBayar == false &&
                    this.state.errBayar == true && (
                      <Alert
                        message={"proses gagal"}
                        description={"proses gagal, cobalah beberapa saat lagi"}
                        type="error"
                        showIcon
                        style={{
                          marginBottom: 10,
                        }}
                      />
                    )}
                  {this.state.loadingBayar == false &&
                    this.state.suksesBayar != null &&
                    this.state.suksesBayar == true && (
                      <Alert
                        message={"transaksi berhasil"}
                        description={
                          <div>
                            kode pembayaran telah dikirim ke email :
                            <span style={{ fontWeight: "bold" }}>
                              {" " + this.state.email_kirim}
                            </span>
                            , jika tidak ditemukan lihat di promotion atau junk
                            email
                          </div>
                        }
                        type="success"
                        showIcon
                        style={{
                          marginBottom: 10,
                        }}
                      />
                    )}
                  {(this.state.suksesBayar == null ||
                    this.state.suksesBayar == false) && (
                      <>
                        <Row justify="space-between">
                          <Col lg={24} sm={24} xs={24}>
                            <Form.Item
                              label={
                                this.state.data != null &&
                                  this.state.data.jenis == 3
                                  ? "Nama / No. Meja"
                                  : "Nama"
                              }
                              name="nama"
                              rules={[
                                {
                                  required: true,
                                  message: "harus diisi",
                                },
                              ]}
                            >
                              <Input
                                style={{ textTransform: "none" }}
                                placeholder={
                                  this.state.data != null &&
                                    this.state.data.jenis == 3
                                    ? "cth: Priyo Nugroho / 23"
                                    : "cth: Priyo Nugroho"
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col lg={24} sm={24} xs={24}>
                            <Form.Item
                              label="Email"
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "harus diisi",
                                },
                                {
                                  type: "email",
                                  message: "format email tidak valid",
                                },
                              ]}
                            >
                              <Input
                                onChange={this.setEmail}
                                style={{ textTransform: "none" }}
                                placeholder="isikan email untuk pemberitahuan status transaksi"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col lg={24} sm={24} xs={24}>
                            <Form.Item
                              label="No Hp"
                              name="no_hp"
                              rules={[
                                {
                                  required: true,
                                  message: "harus diisi",
                                },
                              ]}
                            >
                              <Input
                                style={{ textTransform: "none" }}
                                placeholder="isikan no hp pemesan"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        {/*<Row justify="space-between">
                          <Col lg={24} sm={24} xs={24}>
                            <Form.Item
                              label="METODE PEMBAYARAN"
                              name="metode_pembayaran"
                              rules={[
                                {
                                  required: true,
                                  message: "harus dipiih",
                                },
                              ]}
                            >
                              <Radio.Group 
                                onChange={this.pilihMetodeBayar} 
                                value={this.state.metode_pembayaran}
                                // optionType="button"
                                buttonStyle="solid"
                              >
                                <Radio.Button  value={"kasir"}>Kasir</Radio.Button >
                                <Radio.Button  value={"online"}>Online</Radio.Button >
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </Row>*/}
                        {/*(this.state.metode_pembayaran == "online" &&
                          <Row justify="space-between">
                            <Col lg={24} sm={24} xs={24}>
                              <Form.Item
                                label="PILIH CARA PEMBAYARAN"
                                name="payment"
                                rules={[
                                  {
                                    required: true,
                                    message: "harus dipiih",
                                  },
                                ]}
                              >
                                {this.generateSelect('Payment', configClass.apiUrl.payment, 'id', 'nama')}
                              </Form.Item>
                            </Col>
                          </Row>
                        )*/}
                        <Row justify="end">
                          <Col style={{ padding: 5 }}>
                            <Button
                              key="btn-simpan"
                              loading={this.state.loadingButton}
                              type="primary"
                              htmlType="submit"
                              disabled={this.state.errComponent ? true : false}
                              size="small"
                              size="large"
                            >
                              {!this.state.loadingButton && (
                                <CheckCircleOutlined />
                              )}
                              <>
                                {this.state.data == null && "Pesan"}
                                {this.state.data != null &&
                                  this.state.data.jenis == 1 &&
                                  " Pesan Tiket"}
                                {this.state.data != null &&
                                  this.state.data.jenis == 2 &&
                                  " Pesan Tiket"}
                                {this.state.data != null &&
                                  this.state.data.jenis == 3 &&
                                  " Pesan Menu"}
                                {this.state.data != null &&
                                  this.state.data.jenis == 4 &&
                                  " Pesan"}
                                {this.state.data != null &&
                                  this.state.data.jenis == 5 &&
                                  " Pesan"}
                                {this.state.data != null &&
                                  this.state.data.jenis == 6 &&
                                  " Pesan"}
                                {this.state.data != null &&
                                  this.state.data.jenis == 7 &&
                                  " Pesan Tiket"}
                              </>
                            </Button>
                          </Col>
                          <Col style={{ padding: 5 }}>
                            <Button
                              type="danger"
                              key="btn-cancel"
                              disabled={this.state.loadingButton ? true : false}
                              size="small"
                              onClick={() =>
                                this.setState({ modal_bayar: false })
                              }
                              size="large"
                            >
                              <CloseCircleOutlined /> Tutup
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
        <Modal
          title="CHEKCOUT BERHASIL"
          visible={this.state.modal_instruction}
          onCancel={() => {
            this.setState({
              modal_instruction: false,
              suksesBayar: null,
            }, () => {
              this.reset();
            });
          }}
          footer={[]}
          zIndex={10000}
          style={{
            top: 100,
          }}
        >
          {this.state.detail_instruction != null && (
            <>
              <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                <Col span={9}>ID Transaksi</Col>
                <Col span={15} style={{ textAlign: "right" }}>
                  {this.state.detail_instruction.payment_log.bill_payment.id}
                  <div>
                    <CopyToClipboard
                      text={
                        this.state.detail_instruction.payment_log.bill_payment
                          .id
                      }
                      onCopy={() => {
                        Modal.success({
                          content: "ID Transaksi berhasil di copy",
                          zIndex: 10000,
                        });
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="button"
                        size="small"
                        ghost
                      >
                        <CopyOutlined />
                        salin id transaksi
                      </Button>
                    </CopyToClipboard>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                <Col span={9}>Metode Pembayaran</Col>
                <Col span={15} style={{ textAlign: "right" }}>
                  {this.state.detail_instruction.payment_detail.code.replace(
                    /_/g,
                    " "
                  )}
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                <Col span={9}>Bank/ E-Wallet</Col>
                <Col span={15} style={{ textAlign: "right" }}>
                  <img
                    src={this.state.detail_instruction.payment_detail.icon}
                    height={30}
                  />
                </Col>
              </Row>
              {this.state.detail_instruction.payment_log.bill_payment.receiver_bank_account.bank_code == "qris"
                ?
                <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                  <Col span={24}>
                    <QRCode
                      value={this.state.detail_instruction.payment_log.bill_payment.receiver_bank_account.qr_code_data}
                      size={300}
                      // className={this.state.qrValue == '' ? 'blur-qr' : ''}
                    />
                  </Col>
                </Row>
                :
                <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                  <Col span={9}>Nomor Virtual Account (VA)</Col>
                  <Col span={15} style={{ textAlign: "right" }}>
                    {
                      this.state.detail_instruction.payment_log.bill_payment
                        .receiver_bank_account.account_number
                    }
                    <div>
                      <CopyToClipboard
                        text={
                          this.state.detail_instruction.payment_log.bill_payment
                            .receiver_bank_account.account_number
                        }
                        onCopy={() => {
                          Modal.success({
                            content: "Nomor VA berhasil di copy",
                            zIndex: 10000,
                          });
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="button"
                          size="small"
                          ghost
                        >
                          <CopyOutlined />
                          salin kode VA
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </Col>
                </Row>
              }
              <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                <Col span={9}>Nominal Tagihan</Col>
                <Col span={15} style={{ textAlign: "right" }}>
                  {this.state.detail_instruction.payment_log.bill_payment.amount.toLocaleString(
                    "ID"
                  )}
                  <div>
                    <CopyToClipboard
                      text={
                        this.state.detail_instruction.payment_log.bill_payment
                          .amount
                      }
                      onCopy={() => {
                        Modal.success({
                          content: "nominal tagihan berhasil di copy",
                          zIndex: 10000,
                        });
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="button"
                        size="small"
                        ghost
                      >
                        <CopyOutlined />
                        salin nominal tagihan
                      </Button>
                    </CopyToClipboard>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                <Col span={9}>Bayar Sebelum</Col>
                <Col span={15} style={{ textAlign: "right" }}>
                  {moment(
                    new Date(
                      this.state.detail_instruction.payment_log.expired_date
                    )
                  ).format("LLLL")}
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                <Col span={9}>Status Pembayaran</Col>
                <Col span={15} style={{ textAlign: "right" }}>
                  <div>
                    {this.state.loadingRefresh ? (
                      <Spin />
                    ) : (
                      <>
                        {this.state.detail_instruction.payment_status == "1" ? (
                          <Tag color="orange">
                            Menunggu Pembayaran
                          </Tag>
                        ) : (
                          this.state.detail_instruction.payment_status == "2" ?
                            <Tag color="green">
                              Pembayaran Valid
                            </Tag>
                            :
                            <Tag color="blue">
                              {this.state.detail_instruction.payment_status}
                            </Tag>

                        )}
                        <br />
                        <Button
                          style={{ marginTop: 5 }}
                          type="primary"
                          htmlType="button"
                          size="small"
                          onClick={() =>
                            this.refreshStatus(this.state.detail_instruction.id)
                          }
                        >
                          <ReloadOutlined />
                          refresh status pembayaran
                        </Button>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                <Col span={24} style={{ marginBottom: 10 }}>
                  Petunjuk Tata Cara Pembayaran
                </Col>
                <Col span={24}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        this.state.detail_instruction.payment_detail.catatan,
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </Modal>
        <Modal
          title="RUANG TUNGGU"
          visible={this.state.modal_tunggu}
          // onCancel={() => {
          //   this.setState({ modal_tunggu: false });
          // }}
          footer={[]}
          zIndex={10000}
          style={{
            top: 110,
          }}
          maskClosable={false}
          closeIcon={[]}
          footer={null}
        >
          <Spin spinning={this.state.loadingProduk} label="mengecek traffic...">
            <Row gutter={[12, 12]}>
              <Col span={24} className="panel-ruang-tunggu">
                {this.state.dataTunggu != null &&
                  <>
                    <img
                      src={this.state.data.gambar[0].url}
                    />
                    <div className="ruang-tunggu-title">
                      Ruang Tunggu Penuh
                    </div>
                    <div className="ruang-tunggu-caption">
                      Ada Lebih dari {this.state.dataTunggu.jumlah_koneksi} Pengunjung yang sama seperti anda harap coba beberapa saat lagi
                    </div>
                  </>
                }
              </Col>
              <Col span={12}>
                <Button
                  type="primary"
                  htmlType="button"
                  danger
                  style={{ width: "100%", borderRadius: 5 }}
                  onClick={() => {
                    this.cekRooms();
                  }}
                >
                  {/* <ReloadOutlined />
                <br /> */}
                  Coba Lagi
                </Button>
              </Col>
              <Col span={12}>
                <Link to="/">
                  <Button
                    type="primary"
                    htmlType="button"
                    style={{ width: "100%", borderRadius: 5 }}
                  >
                    {/* <HomeOutlined />
                <br /> */}
                    Kembali Ke Beranda
                  </Button>
                </Link>
              </Col>
            </Row>
          </Spin>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

function withParams(Component) {
  return props => <Component {...props} search={useLocation()} />;
}

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(withParams(DetailProduk));
