import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  Skeleton,
  Card,
  Radio,
  Spin,
  Select,
  Tag,
  Menu,
  Popover
} from "antd";
import {
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  StarOutlined, RightOutlined, CheckCircleOutlined, CloseCircleOutlined, ArrowRightOutlined
} from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import { faStar, faComment, faCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";

import ReactPlayer from 'react-player'

import "antd/dist/antd.css";
import "../assets/styleFe.css";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import "react-awesome-slider/dist/styles.css";

import ReactStars from "react-rating-stars-component";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewsTicker, { Directions } from "react-advanced-news-ticker";
import Fade from 'react-reveal/Fade';

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from 'moment';
import 'moment/locale/id'

moment.locale('id');

const configClass = {
  apiUrl: {
    promo: "web/api/artikel/?kategori=2",
  },
};

const AutoplaySlider = withAutoplay(AwesomeSlider);

class SliderPromo extends PureComponent {
  formRef = React.createRef();
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      loadingPromo: false,
      dataPromo: null,
      errPromo: null,
    };
  }

  componentDidMount() {
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    //  this.props.setConfig({
    //   isMoblie: true
    //  });
    // }

    this.getPromo();
  }

  async getPromo() {
    this.setState({
      loadingPromo: true,
      errPromo: null,
      dataPromo: null,
    });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.promo)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            dataPromo: data,
          });
        } else {
          this.setState({
            errPromo: JSON.stringify(data),
          });
        }
        this.setState({ loadingPromo: false });
      })
      .catch((response) => {
        console.log("gagal_get_Promo", response);
        this.setState({
          errPromo: JSON.stringify(response),
        });
        this.setState({ loadingPromo: false });
      });
  }

  render() {
    return (
      <Col xs={24} sm={24} lg={6} xl={6} className="panel-info-ticker">
        <div className="info-ticker">
          <Fade bottom>
            <Row>
              {(this.state.loadingPromo &&
                <Col span={24}>
                  <i
                    className="fa fa-arrow-up"
                    id="nt-example1-prev"
                  />
                  <NewsTicker
                    ref={this.infoRef}
                    direction={Directions.UP}
                    rowHeight={210}
                    maxRows={3}
                    speed={600}
                    duration={3000}
                    autoStart={true}
                    className="promo-ticker"
                  >

                    {[...Array(3)].map((x, i) => (
                      <div class="post-item">
                        <div class="post-img">
                          <Skeleton
                            active
                            paragraph={false}
                            title={{
                              width: "100%",
                              height: 80
                            }}

                          />
                        </div>
                        <div class="post-text">
                          <div class="post-meta">
                            <h6>
                              <Skeleton
                                title={{
                                  width: "70%"
                                }}
                                active
                                paragraph={false}
                              />
                            </h6>
                            <p class="tgl">
                              <Skeleton
                                active
                                paragraph={false}
                              />
                            </p>
                            <p class="isi">
                              <Skeleton
                                active
                                paragraph={false}
                              />

                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </NewsTicker>
                  <i
                    className="fa fa-arrow-down"
                    id="nt-example1-next"
                  />
                </Col>
              )}
              {this.state.dataPromo != null && (
                <Col span={24}>
                  <i
                    className="fa fa-arrow-up"
                    id="nt-example1-prev"
                    onClick={() => {
                      this.infoRef.current.movePrev();
                      this.infoRef.current.resetInterval();
                    }}
                  />
                  <NewsTicker
                    ref={this.infoRef}
                    direction={Directions.UP}
                    rowHeight={210}
                    maxRows={3}
                    speed={600}
                    duration={3000}
                    autoStart={true}
                    className="promo-ticker"
                  >
                    {this.state.dataPromo.results.map((item, idx) => (
                      <div class="post-item">
                        <div class="post-img">
                          <img
                            src={item.gambar}
                            onError={(e) => {
                              e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + "/no_image.png"
                            }}
                          />
                        </div>
                        <div class="post-text">
                          <div class="post-meta">
                            <h6>{item.judul}</h6>
                            <p class="tgl">
                              {moment(item.created_at).format(
                                "DD-MM-YYYY HH:mm"
                              ) + " WIB"}
                            </p>
                            <p class="isi">
                              {this.config
                                .stripTags(item.content)
                                .substring(0, 80) + " ..."}
                              <Link
                                to={
                                  "/info/" +
                                  item.id +
                                  "/" +
                                  this.config.getSlug(item.judul)
                                }
                                target="_blank"
                              >
                                <button className="btn-selengkapnya">
                                  selengkapnya
                                  <ArrowRightOutlined style={{ marginLeft: 5 }} />
                                </button>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </NewsTicker>
                  <i
                    className="fa fa-arrow-down"
                    id="nt-example1-next"
                    onClick={() => {
                      this.infoRef.current.moveNext();
                      this.infoRef.current.resetInterval();
                    }}
                  />
                </Col>
              )}
            </Row>
          </Fade>
          {this.state.dataPromo != null && (
            <Fade bottom>
              <Row className="heading">
                <Col span={24} className="tengah btn-selengkapnya2">
                  <Link to="/promo">
                    Lihat Promo Selengkapnya
                  </Link>
                </Col>
              </Row>
            </Fade>
          )}
        </div>
      </Col>

    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(SliderPromo);
