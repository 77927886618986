import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  Skeleton,
  Card,
  Radio,
  Spin,
  Select,
  Tag,
  Menu,
  Popover,
  Space,
  Result
} from "antd";
import {
  MenuOutlined,
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  StarOutlined,
  RightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import { faStar, faComment, faCart, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";

import ReactPlayer from "react-player";

import "antd/dist/antd.css";
import "../assets/styleFe.css";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

import ReactStars from "react-rating-stars-component";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import NewsTicker, { Directions } from "react-advanced-news-ticker";
import Fade from 'react-reveal/Fade';

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from "moment";
import "moment/locale/id";
import SliderAtas from "../components/SliderAtas.jsx";
import SliderPromo from "../components/SliderPromo.jsx";

import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);

moment.locale("id");

const configClass = {
  apiUrl: {
    event: "mitra/api/usaha/",
    provinsi: "master/api/provinsi/",
    kabupaten: "master/api/kabupaten/",
    kecamatan: "master/api/kecamatan/",
    kategori: "",
  },
  title: "Event",
};

const AutoplaySlider = withAutoplay(AwesomeSlider);

class Event extends PureComponent {
  formRef = React.createRef();
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      loadingEvent: false,
      dataEvent: [],
      errEvent: null,

      is_expand: false,

      loadingNext: false,
      next: null,

      limit: 12,

      modalFilter: false,

      loadingEvent: false,

      loadingProvinsi: false,
      listProvinsi: null,
      loadingKabupaten: false,
      listKabupaten: null,
      loadingKecamatan: false,
      listKecamatan: null,

      
      filterData: '',
      listFilter: [],

      not_found: false,
    };
  }

  componentDidMount() {
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    //  this.props.setConfig({
    //   isMoblie: true
    //  });
    // }
    window.scrollTo(0, 0)

    this.loadMasterList();

    this.getEvent();

    
  }


  prosesFilter = (values) =>{
    var tempListFilter = [];
    var datas = '';
    for ( var key in values ) {
      if(values[key] != undefined){
        if(datas == ''){
          datas = key+'='+values[key].value;
          tempListFilter = [...tempListFilter, values[key].label];
        }else{
          datas += '&'+key+'='+values[key].value;
          tempListFilter = [...tempListFilter, values[key].label];
        }
      }
    }
    this.setState({
      filterData: datas,
      modalFilter: false,
      listFilter: tempListFilter
    });

    this.getEvent();
  };

  async getEvent(next = false) {
    const url =
      next == true
        ? this.state.next
        : configClass.apiUrl.event +
          "?jenis=7&limit=" +
          this.state.limit +
          "&offset=0&"+this.state.filterData;

    this.setState({
      loadingEvent: next == false ? true : false,
      loadingNext: next == true ? true : false,
      errEvent: null,
      not_found: false,
    });
    if(next == false){
      this.setState({
        dataEvent: []
      });
    }
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", url)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (this.state.dataEvent.length == 0) {
            this.setState({
              dataEvent: data.results,
              next: data.next,
              not_found: data.results.length == 0 ? true : false
            });
          } else {
            var dataTemp = this.state.dataEvent;
            dataTemp = [...dataTemp, ...data.results];
            this.setState({
              dataEvent: dataTemp,
              next: data.next,
            });
          }
          // this.setState({
          //   dataEvent: data,
          // });
        } else {
          this.setState({
            errEvent: JSON.stringify(data),
          });
        }
        this.setState({
          loadingEvent: false,
          loadingNext: false,
        });
      })
      .catch((response) => {
        console.log("gagal_get_wis", response);
        this.setState({
          errEvent: JSON.stringify(response),
        });
        this.setState({
          loadingEvent: false,
          loadingNext: false,
        });
      });
  }

  loadMasterList() {
    const rule = [
      {
        destination: {
          stateProgressName: "loadingProvinsi",
          endPoint: configClass.apiUrl.provinsi,
          stateDestination: "listProvinsi",
        },
        nextDestination: null,
      },
    ];

    this.setState({
      errComponent: false,
    });

    rule.map((item, index) => {
      if (index == rule.length - 1) {
        this.getMasterList(item, true);
      } else {
        this.getMasterList(item);
      }
    });
  }

  async getMasterList(rule, isFinal = false) {
    var destination = rule.destination;
    var nextDestination = rule.nextDestination;
    this.setState({ [destination.stateProgressName]: true });
    this.props
      .ajaxViewHandler("get", destination.endPoint)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (this.state[destination.stateDestination] == null) {
              newState = data;
            } else {
              if (destination.stateProgressName.includes("_searching")) {
                newState = data;
              } else {
                const oldState = this.state[destination.stateDestination];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            // console.log('state_name', destination.stateDestination);
            // console.log('state_value', newState);
            this.setState({
              [destination.stateDestination]: newState,
            });
          } else if (data.results != undefined) {
            this.setState({
              [destination.stateDestination]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                [destination.stateDestination]: data,
              });
            } else {
              this.setState({
                [destination.stateDestination]: [data],
              });
            }
          }
        } else {
          this.setState({
            errComponent: true,
          });
        }
        this.setState({ [destination.stateProgressName]: false });
      })
      .catch((response) => {
        console.log("gagal_get_master", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          errTitle: "gagal request data master",
          errMsg: response,
        });
        this.setState({ [destination.stateProgressName]: false });
      });
    //next function
    if (nextDestination != null) {
      const next = {
        destination: {
          stateProgressName: nextDestination.stateProgressName,
          endPoint: nextDestination.endPoint,
          stateDestination: nextDestination.stateDestination,
        },
        nextDestination: nextDestination.next,
      };
      this.getMasterList(next);
    }
  }

  generateEmptySelect() {
    return <Select placeholder="pilihs" optionFilterProp="children"></Select>;
  }

  generateSelect(stateName, url, key, value) {
    // console.log('state_test', this.state["list"+stateName]);
    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        allowClear
        labelInValue
        style={{zIndex: 10000}}
        showSearch
        placeholder="pilih"
        optionFilterProp="children"
        onSearch={(val) => {
          const rule = {
            destination: {
              stateProgressName: "loading" + stateName + "_searching",
              endPoint: url + "?search=" + val,
              stateDestination: "list" + stateName,
            },
            nextDestination: null,
          };
          this.getMasterList(rule);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
        onChange={(val) => {
          if(val != undefined){
            if (stateName == "Provinsi") {
              // console.log('prov', val)
              const rule = {
                destination: {
                  stateProgressName: "loadingKabupaten",
                  endPoint: configClass.apiUrl.kabupaten + "?province=" + val.value,
                  stateDestination: "listKabupaten",
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            } else if (stateName == "Kabupaten") {
              const rule = {
                destination: {
                  stateProgressName: "loadingKecamatan",
                  endPoint: configClass.apiUrl.kecamatan + "?regencies=" + val.value,
                  stateDestination: "listKecamatan",
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
          // } else if (stateName == "Kecamatan") {
          //   const rule = {
          //     destination: {
          //       stateProgressName: "loadingDesa",
          //       endPoint: configClass.apiUrl.desa + "?district=" + val,
          //       stateDestination: "listDesa",
          //     },
          //     nextDestination: null,
          //   };
          //   this.getMasterList(rule);
          // }
        }}
      >
        {this.state["loading_" + stateName + "_searching"] && (
          <Select.Option value="">
            <Spin size="small" />
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
              <Select.Option value={item[key]}>
                {item[value] + ""}
              </Select.Option>
            ))
          : this.state["list" + stateName].map((item) => (
              <Select.Option value={item[key]}>
                {item[value] + ""}
              </Select.Option>
            ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" />
            </Select.Option>
          )}
      </Select>
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            {configClass.title} | {this.config.get_site_info().name}
          </title>
        </Helmet>

        <Row>
          <Col xs={24} sm={24} lg={18} xl={18}>
            <SliderAtas />
          </Col>
          <SliderPromo />
        </Row>

        <div className="card-panel">
          <div class="garis-panel"></div>
          <div className="konten-panel">
            <div className="blok-gap-netral">
              <Row>
                {(this.state.listFilter.map((item, idx) => (
                  <Col>
                    <Tag color="blue">{item}</Tag>
                  </Col>
                )))}
              </Row>
              <Fade bottom>
                <Row className="heading" style={{paddingBottom: 10}}>
                  <Col span={12} className="kiri">
                    Event
                  </Col>
                  <Col span={12} className="kanan">
                    <button
                      className="btn-netral"
                      onClick={() => {
                        this.setState({
                          modalFilter: true,
                        });
                      }}
                    >
                      Filter <MenuOutlined />
                    </button>
                  </Col>
                </Row>
              </Fade>
              <Row gutter={24}>
                {this.state.loadingEvent == true &&
                  [...Array(12)].map((x, i) => (
                    <Col xs={12} sm={8} lg={4} xl={4} className="item-produk">
                      <div className="panel-item-produk">
                        <div className="panel-gambar-produk">
                          <Skeleton active paragraph={false} />
                        </div>
                        <div
                          className="item-produk-nama"
                          style={{
                            marginTop: 10,
                          }}
                        >
                          <Skeleton
                            active
                            paragraph={false}
                            title={{
                              width: "70%",
                            }}
                          />
                          <Skeleton
                            active
                            paragraph={false}
                            title={{
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  ))}
                {(this.state.not_found == true &&
                  <Col span={24} style={{textAlign: "center"}}>
                    <Result
                      status="404"
                      title="404"
                      subTitle="maaf data yang anda cari tidak ditemukan."
                      // extra={<Button type="primary">Back Home</Button>}
                    />
                  </Col>
                )}
                {this.state.dataEvent != null &&
                  this.state.dataEvent.map((item, idx) => (
                    <Col xs={12} sm={8} lg={4} xl={4} className="item-produk">
                      <Fade bottom>
                      <Link to={"/detail-produk/"+item.slug}>
                        <div className="panel-item-produk">
                          <div className="panel-gambar-produk">
                            <LazyLoadImage
                                className="gbr-produk-grid"
                                key={"gbr-produk"+item.id}
                                alt={item.nama}
                                effect="blur"
                                src={item.gambar[0] != undefined ? item.gambar[0].url+"?tr=w-100" : process.env.PUBLIC_URL + "/no_image.png"} 
                                height={"100%"} 
                                // placeholder={
                                //   <Spin />
                                // }
                                // onClick={() => {
                                //   this.setState({
                                //     image_focus_url: row.payment_detail.icon,
                                //     visible_image_focus: true,
                                //     focus_title: "ICON"
                                //   })
                                // }}
                                // style={{cursor: "pointer"}}
                            />
                            
                          </div>
                          <Row
                            className="item-produk-jenis"
                            justify="space-between"
                          >
                            <Col className="lokasi">
                                {(item.kecamatan_nama != null &&
                                  <>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} style={{marginRight: 5}} />
                                    {item.kecamatan_nama}
                                  </>
                                )}
                            </Col>
                            <Col>
                              <button className="btn-jenis-produk">
                                {item.jenis == 1 && "Wisata"}
                                {item.jenis == 7 && "Event"}
                                {item.jenis == 3 && "Kuliner"}
                              </button>
                            </Col>
                          </Row>
                          <div className="item-produk-nama">{item.nama}</div>
                          <Row className="item-produk-rating">
                            <Col span={8} className="item-produk-rate">
                              <img
                                src={process.env.PUBLIC_URL + "/star.svg"}
                                style={{ height: 10 }}
                              />
                              {item.rating}
                            </Col>
                            <Col span={8} className="item-produk-dilihat">
                              <img
                                src={process.env.PUBLIC_URL + "/dilihat.svg"}
                                style={{ height: 14 }}
                              />
                              {item.page_views}
                            </Col>
                            <Col span={8} className="item-produk-disukai">
                              <img
                                src={process.env.PUBLIC_URL + "/like.svg"}
                                style={{ height: 10 }}
                              />
                              {item.count_like}
                            </Col>
                          </Row>
                        </div>
                      </Link>
                      </Fade>
                    </Col>
                  ))}

                {this.state.loadingNext == true &&
                  [...Array(2)].map((x, i) => (
                    <Col xs={12} sm={8} lg={4} xl={4} className="item-produk">
                      <div className="panel-item-produk">
                        <div className="panel-gambar-produk">
                          <Skeleton active paragraph={false} />
                        </div>
                        <div
                          className="item-produk-nama"
                          style={{
                            marginTop: 10,
                          }}
                        >
                          <Skeleton
                            active
                            paragraph={false}
                            title={{
                              width: "70%",
                            }}
                          />
                          <Skeleton
                            active
                            paragraph={false}
                            title={{
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  ))}
                {this.state.next != null && (
                  <Col span={24} style={{ textAlign: "center", clear: "both" }}>
                    <button
                      className="btn-selengkapnya"
                      onClick={() => {
                        this.getEvent(true);
                      }}
                    >
                      Event Selanjutnya
                    </button>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </div>

        <Modal
          title="FILTER WISATA"
          visible={this.state.modalFilter}
          onCancel={() => {
            this.setState({ modalFilter: false });
          }}
          footer={[]}
          zIndex={10000}
        >
          <Form
            labelCol={{ xs: { span: 6 } }}
            wrapperCol={{ xs: { span: 18 } }}
            initialValues={{ remember: true }}
            onFinish={this.prosesFilter}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item label="Provinsi" name="provinsi">
              {this.state.listProvinsi != null
                ? this.generateSelect(
                    "Provinsi",
                    configClass.apiUrl.provinsi,
                    "id",
                    "name"
                  )
                : this.generateEmptySelect()}
            </Form.Item>

            <Form.Item label="Kabupaten/ Kota" name="kabupaten">
              {this.state.listKabupaten != null
                ? this.generateSelect(
                    "Kabupaten",
                    configClass.apiUrl.kabupaten,
                    "id",
                    "name"
                  )
                : this.generateEmptySelect()}
            </Form.Item>
            <Form.Item label="Kecamatan" name="kecamatan">
              {this.state.listKecamatan != null
                ? this.generateSelect(
                    "Kecamatan",
                    configClass.apiUrl.kecamatan,
                    "id",
                    "name"
                  )
                : this.generateEmptySelect()}
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  Filter
                </Button>
                <Button 
                  type="danger" 
                  htmlType="button"
                  onClick={() =>{
                    this.setState({
                      modalFilter: false
                    });
                  }}
                >
                  Tutup
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(Event);
