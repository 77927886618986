import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  Skeleton,
  Card,
  Radio,
  Spin,
  Select,
  Tag,
  Menu,
  Popover,
} from "antd";
import {
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  StarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ArrowRightOutlined,
  RightOutlined,
  QrcodeOutlined
} from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import { faStar, faComment, faCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";

import ReactPlayer from "react-player";

import "antd/dist/antd.css";
import "../assets/styleFe.css";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

import ReactStars from "react-rating-stars-component";
import Fade from "react-reveal/Fade";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from "moment";
import "moment/locale/id";

import QRCode from "qrcode.react";

import ReactGA from "react-ga";
ReactGA.pageview(window.location.pathname + window.location.search);

moment.locale("id");

const configClass = {
  apiUrl: {
    data: "transaksi/api/transaksi/",
    orders: "transaksi/api/orders/?transaksi="
  },
  // title: "Term & Conditions",
};

class RiwayatTransaksiDetail extends PureComponent {
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      loading: false,
      data: null,
      err: null,

      loadingOrders: false,
      dataOrders: null,
      errOrders: null,

      modal_etiket: false,
      value_etiket: "",
      nama_etiket: "",
      qty_etiket: 0,
    };
  }

  componentDidMount() {
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    //  this.props.setConfig({
    //   isMoblie: true
    //  });
    // }
    window.scrollTo(0, 0);

    this.getData();
  }

  async getData() {
    this.setState({
      loading: true,
      err: null,
      data: null,
    });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.data+this.props.match.params.id+"/", null, "login")
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            data: data,
          }, () =>{
            if(data.status == 2){
              this.getOrders(this.props.match.params.id);
            }
          });
        } else {
          this.setState({
            err: JSON.stringify(data),
          });
        }
        this.setState({ loading: false });
      })
      .catch((response) => {
        console.log("gagal_get_term", response);
        this.setState({
          err: JSON.stringify(response),
        });
        this.setState({ loading: false });
      });
  }

  async getOrders(id) {
    this.setState({
      loadingOrders: true,
      errOrders: null,
      dataOrders: null,
    });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.orders+id, null, "login")
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            dataOrders: data,
          });
        } else {
          this.setState({
            err: JSON.stringify(data),
          });
        }
        this.setState({ loadingOrders: false });
      })
      .catch((response) => {
        console.log("gagal_get_term", response);
        this.setState({
          err: JSON.stringify(response),
        });
        this.setState({ loadingOrders: false });
      });
  }

  render() {
    if(this.props.isLogin == false) {
      return <Redirect to={"/"} />;
    }
    return (
      <>
        <Helmet>
          <title>
            {"Detail Riwayat Transaksi | " + this.config.get_site_info().name}
          </title>
        </Helmet>

        <div className="blok-gap-netral single-content">
          {this.state.loading && (
            <Row className="heading">
              <Col span={12} className="kiri">
                <Skeleton active paragraph={true} />
              </Col>
            </Row>
          )}
          {this.state.data != null && (
            <div className="heading">
              <div style={{display: "inline-block"}}>
                <Link to="/riwayat-transaksi">
                  <h3>Riwayat Transaksi</h3>
                </Link>
              </div>
              <div style={{display: "inline-block", marginLeft: 5}}>
                >
              </div>
              <div style={{display: "inline-block", marginLeft: 5}}>
                <h3>Detail</h3>
              </div>
            </div>
          )}
          {this.state.data != null &&
              
                <Row
                  style={{ 
                    border: "1px solid #25BEF5", 
                    padding: 5,
                    borderRadius: 5,
                    marginBottom: 20
                  }}
                  gutter={[16, 16]}
                >
                  <Col span={24}>
                    {moment(this.state.data.created_at).format("LLLL")}
                    <br />
                    {"Kode : " + this.state.data.kode}
                    <br />
                    {this.state.data.payment_log.bill_payment != undefined && "Total : Rp. " +
                      this.state.data.payment_log.bill_payment.amount.toLocaleString("ID")}
                    <br />
                    Batas Akhir Pembayaran: 
                    {moment(
                      new Date(
                        this.state.data.payment_log.expired_date
                      )
                    ).format("LLLL")}
                    <br />
                    Status Pembayaran:
                    {this.state.data.status == 1 && <Tag color="warning">menunggu pembayaran</Tag>}
                    {this.state.data.status == 2 && <Tag color="success">pembayaran valid</Tag>}
                    {this.state.data.status == 3 && <Tag color="error">pembayaran tidak valid</Tag>}
                    {this.state.data.status == 4 && <Tag color="success">expired</Tag>}
                    {this.state.data.status == 2
                      ?
                        <>
                          <div
                            style={{
                              border: "1px solid #469AF8",
                              borderRadius: 5,
                              marginTop: 10,
                              fontSize: 11,
                            }}
                          >
                            
                              {this.state.dataOrders != null && this.state.dataOrders.results.map((item, idx) =>(
                                  <Row style={{borderBottom: "1px solid #ccc"}}>
                                    <Col span={4} style={{background: "#ccc"}}>
                                      <img src={item.gambar[0].url} width={60} />
                                    </Col>
                                    <Col span={20} style={{background: "#ccc", padding: 5}}>
                                      {item.usaha_nama}<br />
                                      {item.approved_status == 1 && <Tag color="warning">menunggu konfirmasi mitra</Tag>}
                                      {item.approved_status == 2 && <Tag color="success">diterima oleh mitra</Tag>}
                                      {item.approved_status == 3 && <Tag color="error">ditolak oleh mitra</Tag>}
                                    </Col>
                                    <Col span={24}>
                                      <ul style={{paddingInlineStart: 20}}>
                                      {item.details.map((itemDetail, idxDetail) =>(
                                        <li>
                                        <Row style={{padding: 5, borderBottom: "1px solid #ccc"}}>
                                          <Col span={3}><img src={itemDetail.gambar[0].url} height={30} /></Col>
                                          <Col span={17}>
                                            {itemDetail.quantity+" x "+itemDetail.produk_nama}<br />
                                            <span style={{fontSize: 10, fontStyle: "italic"}}>
                                              {itemDetail.checkin_at != null ? "checkin at : "+moment(itemDetail.checkin_at).format("LLLL") : ""}
                                              {item.approved_status == 1 && "E-Tiket akan didapatkan setelah mitra mengkonfirmasi"}
                                            </span>
                                          </Col>
                                          <Col span={4} style={{textAlign: "right"}}>
                                            {item.approved_status == 2 && itemDetail.checkin_at == null &&
                                              <Button
                                                type="primary"
                                                size="small"
                                                onClick={() =>{
                                                  this.setState({
                                                    modal_etiket: true,
                                                    value_etiket: itemDetail.qr,
                                                    nama_etiket: itemDetail.produk_nama,
                                                    qty_etiket: itemDetail.quantity,
                                                  })
                                                }}
                                              >
                                                E-TIket
                                              </Button>
                                            }                                            
                                          </Col>

                                        </Row>
                                        </li>
                                      ))}
                                      </ul>
                                    </Col>
                                  </Row>
                                
                              ))}
                            
                          </div>
                        </>
                      :
                        <>
                          <div
                            style={{
                              border: "1px solid #469AF8",
                              borderRadius: 5,
                              marginTop: 10,
                              fontSize: 11,
                            }}
                          >
                            <ul style={{paddingInlineStart: 20}}>
                              {this.state.data.details.map((item, idx) =>(
                                <li>
                                  <Row style={{padding: 5, borderBottom: "1px solid #ccc"}}>
                                    <Col span={24}>
                                      {item.quantity+" x "+item.produk_nama}
                                    </Col>
                                  </Row>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                    }
                  </Col>
                </Row>
              
            }
        </div>
        <Modal
          title="E-TIKET"
          visible={this.state.modal_etiket}
          onCancel={() => {
            this.setState({ modal_etiket: false });
          }}
          footer={[]}
          zIndex={10000}
        >
          <div style={{textAlign: "center"}}>
            <QRCode 
              value={this.state.value_etiket} 
              size={300}
              // className={this.state.value_etiket==''?'blur-qr':''}
            />
            <div style={{clear: "both"}}>
              {this.state.qty_etiket+" x "+this.state.nama_etiket}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(RiwayatTransaksiDetail);
