import React, { Component }  from 'react';
import Cookies from 'js-cookie';

class Config{
	/*
	jenis layanan: 1 home care, ambulance 2, laboratorium 3, obat 4
	*/
	get_site_info(){
		return {
			name: 'Plesiran',
			logo: process.env.PUBLIC_URL + "/logo.png",
			logo_landscape: process.env.PUBLIC_URL + "/logo.png",
			description: 'Plesiran'
		}
	}

	get_server_url(){
		// const prod = 'https://staging-api-plesiran.proitdev.com';
		// const dev = 'https://staging-api-plesiran.proitdev.com';
		const prod = 'https://api.plesiran.co.id';
		const dev = 'https://api.plesiran.co.id';

		if(Cookies.get("mode") == "prod"){
			return prod;
		}else{
			return dev;
		}
	}
	
	get_base_url(){
		const prod = 'https://api.ritxbertani.com';
		const dev = 'https://ritx.proitdev.com';

		if(Cookies.get("is_prod")){
			return prod;
		}else{
			return dev;
		}
	}

	get_web_socket(){
		return 'wss://api-slamet.proitdev.com/messages/provider/';
	}

	get_cookie_name(){
		return 'r4npL_cr4uth';
	}

	stripTags(teks){
		var temp = teks.replace(/&nbsp;/, "");
		return temp.replace(/(<([^>]+)>)/ig, "");//strip tags html
	}

	escape_str(value){
		return(
			<div>
				{value.includes("https://") || value.includes("http://") || value.includes("www.")
					?
						<div>
								<a href={value} target="_blank">{value}</a>
						</div>
					:
						value
				}
			</div>
		);
	}

	cm_to_pixel(cm){
		return cm*37.7952755906;
	}

	getSlug(teks){
		//Peresmian Jalan Tol Serang – Panimbang, Presiden Jokowi: Infrastruktur adalah Fondasi Negara Kita untuk Bersaing Dengan Negara Lain
		var temp = teks.trim();
		temp = temp.replace(/-/g, '');//dash
		temp = temp.replace(/–/g, '');//dash special
		temp = temp.replace(/\s+/g, '-').toLowerCase();//space
		temp = temp.replace(/(<([^>]+)>)/ig, "");//strip tags html
		// temp = temp.replace(/,()/ig, "");//()
		temp = temp.replace(/[,+\/*%@!?&$#^:'"{}()<>:'"]/g, "");
		return encodeURIComponent(temp);
	}
}
export default Config
