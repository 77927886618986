import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  Skeleton,
  Card,
  Radio,
  Spin,
  Select,
  Tag,
  Menu,
  Popover
} from "antd";
import { 
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  StarOutlined, RightOutlined, CheckCircleOutlined, CloseCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import { faStar, faComment, faCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";

import ReactPlayer from 'react-player'

import "antd/dist/antd.css";
import "../assets/styleFe.css";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import "react-awesome-slider/dist/styles.css";

import ReactStars from "react-rating-stars-component";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from 'moment';
import 'moment/locale/id'
moment.locale('id');

const configClass = {
  apiUrl: {
    
  }
};

class Footer extends PureComponent {
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      is_expand: false,
    };
  }

  componentDidMount() {
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    //  this.props.setConfig({
    //   isMoblie: true
    //  });
    // }
    this.props.setConfig({
      isMobile: window.innerWidth <= 760 ? true : false
    });
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
      this.props.setConfig({
        isMobile: window.innerWidth <= 760 ? true : false
      });
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.resize.bind(this));
  }

  expandMenu = () =>{
    const temp = this.state.is_expand;
    this.setState({
      is_expand: !temp
    });
  };


  render() {

    return (
      <div className="footernya copyright" >
        <Row tyle={{marginTop: 30}}>
          <Col span={24} className="footer-atas">
            <Row className="panel-footer">
              <Col xs={12} md={8} lg={8}>
                <div className="footer-header">
                  <img src={process.env.PUBLIC_URL + "/logo.png"} height={40} />
                  <img src={process.env.PUBLIC_URL + "/logo_teks_biru.png"}  height={40} style={{marginLeft: 5}} />
                </div>
                <div>Jl. Magelang Kopeng km 10</div>
                <div>dsn. Wonosuko,Tegalrejo</div>
                <div>Kab. Magelang 56192</div>
              </Col>
              <Col span={8} className="center footer-tengah">
                <div className="footer-header">Download Aplikasi</div>
                <Row>
                <Col span={12}>
                  <a href={"https://play.google.com/store/apps/details?id=al.plesiran.app&hl=en&gl=US"} target="_blank">
                    <img src={process.env.PUBLIC_URL + "/gplay.png"} height={50} /><br />
                    Aplikasi User
                  </a>
                </Col>
                <Col span={12}>
                  <a href={"https://play.google.com/store/apps/details?id=com.mitraplesiran"} target="_blank">
                    <img src={process.env.PUBLIC_URL + "/gplay.png"} height={50} /><br />
                    Aplikasi Mitra
                  </a>
                </Col>
                </Row>
              </Col>
              <Col xs={12} md={8} lg={8} className="teks-kanan">
                <div className="footer-header">Kontak</div>
                <div>plesirandigitalindonesia@gmail.com</div>
                <div>Tlp. (0293) 319-7473</div>
                <div>Hp. 08112637341</div>
                <div>WA. 08112637341</div>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="footer-bawah">
            <div className="gplay-mobile">
              <img src={process.env.PUBLIC_URL + "/gplay.png"} height={30} className="gplay-mobile" />
            </div>
            <div>
              &copy; 2022 PT. Plesiran Digital Indonesia
              <br />
              v2.0
              <br />
              <Link to="/term-conditions">
                Term &amp; Condiitons
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(Footer);
