import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import {
    Button,
    Row,
    Col,
    Input,
    Layout,
    Form,
    Alert,
    Modal,
    message,
    Switch,
    Tabs,
    Skeleton,
    Card,
    Radio,
    Spin,
    Select,
    Tag,
    Menu,
    Popover
} from "antd";
import {
    UserOutlined,
    DownOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    StarOutlined, RightOutlined, CheckCircleOutlined, CloseCircleOutlined, ArrowRightOutlined
} from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import { faStar, faComment, faCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";

import ReactPlayer from 'react-player'

import "antd/dist/antd.css";
import "../assets/styleFe.css";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import "react-awesome-slider/dist/styles.css";

import ReactStars from "react-rating-stars-component";
import Fade from 'react-reveal/Fade';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from 'moment';
import 'moment/locale/id'

import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);

moment.locale('id');

const configClass = {
    apiUrl: {
        data: "web/api/artikel/?kategori=1",
    },
    // title: "Term & Conditions",
};


class Info extends PureComponent {
    config;

    constructor(props) {
        super(props);
        this.config = new Config();
        this.state = {
            loading: false,
            data: null,
            err: null,

            loadingNext: false,
            next: null,

            limit: 2,
        };
    }

    componentDidMount() {
        // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
        //  this.props.setConfig({
        //   isMoblie: true
        //  });
        // }
        window.scrollTo(0, 0)

        this.getData();
    }

    async getData(next = false) {
        const url =
            next == true
                ? this.state.next
                : configClass.apiUrl.data +
                "&limit=" +
                this.state.limit +
                "&offset=0";

        this.setState({
            loading: next == false ? true : false,
            loadingNext: next == true ? true : false,
            err: null,
        });
        if (next == false) {
            this.setState({
                data: []
            });
        }
        //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

        this.props
            .ajaxViewHandler("get", url)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    if (this.state.data.length == 0) {
                        this.setState({
                            data: data.results,
                            next: data.next,
                        });
                    } else {
                        var dataTemp = this.state.data;
                        dataTemp = [...dataTemp, ...data.results];
                        this.setState({
                            data: dataTemp,
                            next: data.next,
                        });
                    }
                    // this.setState({
                    //   dataKuliner: data,
                    // });
                } else {
                    this.setState({
                        err: JSON.stringify(data),
                    });
                }
                this.setState({
                    loading: false,
                    loadingNext: false,
                });
            })
            .catch((response) => {
                console.log("gagal_get_info", response);
                this.setState({
                    err: JSON.stringify(response),
                });
                this.setState({
                    loading: false,
                    loadingNext: false,
                });
            });
    }

    render() {

        return (
            <>

                <Helmet>
                    <title>
                        {"Info Plesiran"} | {this.config.get_site_info().name}
                    </title>
                </Helmet>


                <div className="blok-gap-netral single-content">
                    <Row className="heading">
                    <Col span={24} className="kiri">
                        Info Plesiran
                    </Col>
                        
                        {(this.state.loading &&
                            <Col span={12} className="kiri">
                                <Skeleton active paragraph={true} />
                            </Col>
                        )}
                        <Col span={24}>
                            {(this.state.data != null && this.state.data.map((item, idx) => (
                                <Row className="row-info">
                                    <Col md={{ span: 5}} sm={{ span: 24}}>
                                        <img src={item.gambar} />
                                    </Col>
                                    <Col md={{ span: 19}} sm={{ span: 24}} className="caption">
                                        <div span={24} className="judul">
                                            {item.judul}
                                        </div>
                                        <div span={24} className="tgl">
                                            {item.update_at != null
                                                ?
                                                moment(item.update_at).format("DD-MMM-YYYY HH:mm")
                                                :
                                                moment(item.created_at).format("DD-MMM-YYYY HH:mm")
                                            }
                                        </div>
                                        <div className="isi">
                                            {this.config.stripTags(item.content).substring(0, 120) + " ..."}
                                            <br />
                                            <Link to={"/info/detail/" + item.id + "/" + this.config.getSlug(item.judul)}>
                                                <button
                                                    className="btn-selengkapnya"
                                                >
                                                    selengkapnya
                                                    <ArrowRightOutlined style={{ marginLeft: 5 }} />
                                                </button>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            )))}
                        </Col>
                        {this.state.loadingNext == true &&
                            [...Array(2)].map((x, i) => (
                                <Col span={12} className="kiri">
                                    <Skeleton active paragraph={true} />
                                </Col>
                            ))
                        }
                        {this.state.next != null && (
                            <Col span={24} style={{ textAlign: "center", clear: "both", paddingTop: 10 }}>
                                <button
                                    className="btn-selengkapnya"
                                    onClick={() => {
                                        this.getData(true);
                                    }}
                                >
                                    Info Selanjutnya
                                </button>
                            </Col>
                        )}
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = function (state) {
    return state.configReducers;
};

export default connect(mapStateToProps, {
    setConfig,
    ajaxHandler,
    ajaxViewHandler,
})(Info);
