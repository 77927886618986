import React, { PureComponent } from "react";
import { Redirect, Link, NavLink, withRouter } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  Skeleton,
  Card,
  Radio,
  Spin,
  Select,
  Tag,
  Menu,
  Popover,
  notification,
} from "antd";
import {
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HistoryOutlined,
  LogoutOutlined,
  StarOutlined,
  RightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import { faStar, faComment, faCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";

import ReactPlayer from "react-player";

import "antd/dist/antd.css";
import "../assets/styleFe.css";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

import ReactStars from "react-rating-stars-component";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from "moment";
// import { signInWithGoogle } from '../service/firebase';
// import firebase from '../service/firebase';
import { GoogleLogin } from "@react-oauth/google";

import "moment/locale/id";
moment.locale("id");

const configClass = {
  apiUrl: {
    register: "auth/api/signup/google/",
    profile: "auth/api/user/info/",
  },
};

class Header extends PureComponent {
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      is_expand: false,
      redirect: false,
      loadingProfile: false,
      dataProfile: null,
      modalLogout: false,
    };
  }

  componentDidMount() {
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    //  this.props.setConfig({
    //   isMoblie: true
    //  });
    // }
    this.props.setConfig({
      isMobile: window.innerWidth <= 768 ? true : false,
    });
    window.addEventListener("resize", this.resize.bind(this));

    // firebase.auth().onAuthStateChanged(user => {
    //   console.log('user', user)
    //   var datas = {
    //     token: user.refreshToken,
    //     level: 4
    //   }
    //   this.postData(datas)

    // })

    if (this.props.isLogin) {
      this.getProfile();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.isLogin) != JSON.stringify(this.props.isLogin)
    ) {
      if (this.props.isLogin) {
        this.getProfile();
      }
    }
  }

  prosesLogout() {
    this.setState(
      {
        modalLogout: false,
      },
      () => {
        Cookies.remove(this.config.get_cookie_name());
        this.props.setConfig({
          isLogin: false,
        });
      }
    );
  }

  async getProfile() {
    this.setState({
      loadingProfile: true,
    });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.profile, null, "login")
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            dataProfile: data.nama,
            isLogin: true,
          });
        } else {
          console.log("gagal_profile", data);
          if (this.props.responseCode == 401) {
            Cookies.remove(this.config.get_cookie_name());
            this.props.setConfig({
              isLogin: false,
            });
          } else {
            Cookies.remove(this.config.get_cookie_name());
            this.props.setConfig({
              isLogin: false,
            });
          }
        }
        this.setState({ loadingProfile: false });
      })
      .catch((response) => {
        console.log("gagal_profile", response);
        console.log(JSON.stringify(response));
        this.setState({ loadingProfile: false });
      });
  }

  async postData(datas) {
    this.setState({
      loading: true,
    });

    this.props
      .ajaxHandler("post", configClass.apiUrl.register, datas, false, true)
      .then(() => {
        this.setState({
          loading: false,
        });
        const response = this.props.responseMsg;
        if (this.props.isSuccess) {
          // alert(JSON.stringify(response))
          Cookies.set(
            this.config.get_cookie_name(),
            "Bearer " + response.access,
            { expires: 1 }
          );
          this.setState({
            loading: false,
            isLogin: true,
          }, () => {
            this.props.setConfig({
              isLogin: true
            });
          });
        } else {
          this.setState({
            loading: false,
          });
          notification.error({
            message: `autentikasi gagal`,
            description: JSON.stringify(response),
            palcement: "bottomRight",
          });
        }
      })
      .catch((response) => {
        notification.error({
          message: `autentikasi gagal`,
          description: JSON.stringify(response),
          palcement: "bottomRight",
        });
        console.log("gagal_post", response);
        alert(JSON.stringify(this.props.responseMsg));
      });
  }

  resize() {
    this.props.setConfig({
      isMobile: window.innerWidth <= 768 ? true : false,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  expandMenu = () => {
    const temp = this.state.is_expand;
    this.setState({
      is_expand: !temp,
    });
  };

  // setPencarian = (e) =>{
  //   this.props.setConfig({

  //   });
  // }

  prosesPencarian = (val) => {
    this.props.setConfig({
      pencarian: val,
    });
    this.props.history.push({
      pathname: "/pencarian",
    });
    // this.setState({
    //   redirect: true
    // });
  };

  render() {
    // if(this.props.pencarian != undefined){
    //   return <Redirect to="/pencarian" />
    // }

    // const login = useGoogleLogin({
    //   onSuccess: codeResponse => console.log(codeResponse),
    //   flow: 'auth-code',
    // });

    return (
      <>
        {(this.state.is_expand == true || this.props.isMobile == false) && (
          <Menu
            mode={this.props.isMobile == true ? "inline" : "horizontal"}
            className="menu-utama"
            id="menu-utama"
          >
            {this.props.isMobile == false && (
              <Menu.Item className="logo-menu">
                <Link to="/">
                  <img src={process.env.PUBLIC_URL + "/logo.png"} />
                  <img
                    src={process.env.PUBLIC_URL + "/logo_teks.png"}
                    style={{ marginLeft: 5 }}
                  />
                </Link>
              </Menu.Item>
            )}
            <Menu.Item>
              <Link to="/" onClick={this.expandMenu}>
                Beranda
              </Link>
            </Menu.Item>
            {this.props.isMobile == false && (
              <Menu.SubMenu title="Produk">
                <Menu.Item>
                  <Link to="/wisata">
                    <img
                      src={process.env.PUBLIC_URL + "/icon-wisata.svg"}
                      height={20}
                      style={{ marginRight: 10 }}
                    />
                    Wisata
                  </Link>
                </Menu.Item>
                {/* <Menu.Item>
                  <Link to="/transportasi">
                    <img
                      src={process.env.PUBLIC_URL + "/icon-transportasi.svg"}
                      height={20}
                      style={{ marginRight: 10 }}
                    />
                    Transportasi
                  </Link>
                </Menu.Item> */}
                <Menu.Item>
                  <Link to="/kuliner">
                    <img
                      src={process.env.PUBLIC_URL + "/icon-kuliner.svg"}
                      height={20}
                      style={{ marginRight: 10 }}
                    />
                    Kuliner
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/event">
                    <img
                      src={process.env.PUBLIC_URL + "/icon-event.png"}
                      height={20}
                      style={{ marginRight: 10 }}
                    />
                    Event
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            )}
            <Menu.SubMenu title="Tentang">
              <Menu.Item>
                <Link to="/term-conditions" onClick={this.expandMenu}>
                  Term &amp; Conditions
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/tentang-plesiran" onClick={this.expandMenu}>
                  Tentang Plesiran
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            {this.props.isLogin && (
              <Menu.Item>
                <Link to="/tiketku">
                  Tiketku
                </Link>
              </Menu.Item>
            )}

            {this.props.isMobile == false && (
              <Menu.Item className="menu-cari">
                <Input.Search
                  className="cari-atas"
                  placeholder={
                    this.props.pencarian != undefined
                      ? this.props.pencarian
                      : "cari..."
                  }
                  // enterButton
                  onSearch={this.prosesPencarian}
                  allowClear
                // value={this.props.pencarian}
                />
              </Menu.Item>
            )}
            {this.props.isMobile == false && (
              <Menu.SubMenu title={<UserOutlined />} className="menu-akun">
                {this.props.isLogin && (
                  <Menu.Item style={{ borderBottom: "1px solid #ccc" }}>
                    <UserOutlined /> {this.state.dataProfile}
                  </Menu.Item>
                )}
                {this.props.isLogin && (
                  <Menu.Item>
                    <Link to="/tiketku">
                      <HistoryOutlined /> Tiketku
                    </Link>
                  </Menu.Item>
                )}
                {this.props.isLogin && (
                  <Menu.Item
                    onClick={() => {
                      this.setState({
                        modalLogout: true,
                      });
                    }}
                  >
                    <LogoutOutlined /> Logout
                  </Menu.Item>
                )}
                {this.props.isLogin == false && (
                  <Menu.Item>
                    <GoogleLogin
                      onSuccess={(credentialResponse) => {
                        // console.log(credentialResponse);
                        var datas = {
                          token: credentialResponse.credential,
                          level: 4,
                          tipe: 4,
                        };
                        this.postData(datas);
                      }}
                      onError={() => {
                        Modal.error({
                          title: "Login Gagal",
                          content: "gagal login, cobalah beberapa saat lagi",
                          zIndex: 10000,
                        });
                      }}
                      text={"signin_with"}
                      theme={"outline "}
                    />
                  </Menu.Item>
                )}
              </Menu.SubMenu>
            )}
          </Menu>
        )}

        {this.props.isMobile == true && (
          <Row className="menu-utama-mobile" id="menu-utama">
            <Col>
              <Button
                type="primary"
                style={{ marginBottom: 16 }}
                onClick={this.expandMenu}
              >
                {this.state.is_expand == true ? (
                  <MenuUnfoldOutlined />
                ) : (
                  <MenuFoldOutlined />
                )}
              </Button>
            </Col>
            <Col>
              <Link to="/">
                <img src={process.env.PUBLIC_URL + "/logo.png"} />
              </Link>
            </Col>
            <Col>
              <Link to="/">
                <img src={process.env.PUBLIC_URL + "/logo_teks.png"} />
              </Link>
            </Col>
            <Col flex="auto" style={{ textAlign: "right" }}>
              <Row justify="end">
                <Col style={{ padding: 0 }}>
                  <Input.Search
                    className="cari-atas"
                    placeholder="cari..."
                    // enterButton
                    onSearch={this.prosesPencarian}
                  />
                </Col>
                <Col style={{ padding: 0 }}>
                  <Popover
                    className="pop-over"
                    trigger="click"
                    placement="bottomRight"
                    content={
                      <div style={{ textAlign: "right" }}>
                        {this.props.isLogin ? (
                          <>
                            <Link to="/tiketku">
                              <Button
                                type="text"
                                style={{ border: "none" }}
                              >
                                Tiketku
                              </Button>
                            </Link>
                            <br />
                            <Button
                              style={{ marginTop: 5, border: "none" }}
                              type="text"
                              onClick={() => {
                                this.setState({
                                  modalLogout: true,
                                });
                              }}
                            >
                              Logout
                            </Button>
                            <br />
                          </>
                        ) : (
                          <GoogleLogin
                            onSuccess={(credentialResponse) => {
                              // console.log(credentialResponse);
                              var datas = {
                                token: credentialResponse.credential,
                                level: 4,
                                tipe: 4,
                              };
                              this.postData(datas);
                            }}
                            onError={() => {
                              Modal.error({
                                title: "Login Gagal",
                                content:
                                  "gagal login, cobalah beberapa saat lagi",
                                zIndex: 10000,
                              });
                            }}
                            text={"signin_with"}
                            theme={"outline "}
                          />
                        )}
                      </div>
                    }
                    title={
                      <div style={{ borderBottom: "1px solid #ccc", textAlign: "right", paddingBottom: 5 }}>
                        {this.props.isLogin ? this.state.dataProfile : "PILIH"}
                      </div>
                    }
                  >
                    <Button className="tbl-text">
                      <UserOutlined />
                    </Button>
                  </Popover>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <Modal
          title="Konfirmasi"
          visible={this.state.modalLogout}
          onOk={() => {
            this.prosesLogout();
          }}
          onCancel={() => {
            this.setState({
              modalLogout: false,
            });
          }}
          style={{
            top: 100,
          }}
          okText="Ya"
          cancelText="Tidak"
          zIndex={10000}
        >
          <p>apakah anda yakin untuk logout?</p>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(withRouter(Header));
