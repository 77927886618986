import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  Skeleton,
  Card,
  Radio,
  Spin,
  Select,
  Tag,
  Menu,
  Popover,
} from "antd";
import {
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  StarOutlined,
  RightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import {
  faStar,
  faComment,
  faCart,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";

import ReactPlayer from "react-player";

import "antd/dist/antd.css";
import "../assets/styleFe.css";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

import ReactStars from "react-rating-stars-component";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from "moment";
import "moment/locale/id";
import NewsTicker, { Directions } from "react-advanced-news-ticker";

import { LazyLoadImage } from "react-lazy-load-image-component";


import SliderAtas from "../components/SliderAtas.jsx";
import SliderPromo from "../components/SliderPromo.jsx";
import SliderPromoMobile from "../components/SliderPromoMobile.jsx";

import Fade from 'react-reveal/Fade';

import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);

moment.locale("id");

const configClass = {
  apiUrl: {
    wisata_favorit: "mitra/api/usaha/",
    artikel: "web/api/artikel/?kategori=1",
  },
  title: "Beranda",
};

const AutoplaySlider = withAutoplay(AwesomeSlider);

class Beranda extends PureComponent {
  formRef = React.createRef();
  infoRef = React.createRef();

  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      loadingWisataFavorit: false,
      dataWisataFavorit: null,
      errWisataFavorit: null,

      loadingArtikel: false,
      dataArtikel: null,
      errArtikel: null,


      is_expand: false,
    };
  }

  componentDidMount() {
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    //  this.props.setConfig({
    //   isMoblie: true
    //  });
    // }

    // this.getSlider();
    window.scrollTo(0, 0);

    this.getWisataFavorit();
    this.getArtikel();


  }

  async getWisataFavorit() {
    this.setState({
      loadingWisataFavorit: true,
      errWisataFavorit: null,
      dataWisataFavorit: null,
    });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler(
        "get",
        window.innerWidth <= 768
          ? configClass.apiUrl.wisata_favorit + "?limit=6"
          : configClass.apiUrl.wisata_favorit + "?limit=12"
      )
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            dataWisataFavorit: data,
          });
        } else {
          this.setState({
            errWisataFavorit: JSON.stringify(data),
          });
        }
        this.setState({ loadingWisataFavorit: false });
      })
      .catch((response) => {
        console.log("gagal_get_wisfar", response);
        this.setState({
          errWisataFavorit: JSON.stringify(response),
        });
        this.setState({ loadingWisataFavorit: false });
      });
  }

  async getArtikel() {
    this.setState({
      loadingArtikel: true,
      errArtikel: null,
      dataArtikel: null,
    });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.artikel)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            dataArtikel: data,
          });
        } else {
          this.setState({
            errArtikel: JSON.stringify(data),
          });
        }
        this.setState({ loadingArtikel: false });
      })
      .catch((response) => {
        console.log("gagal_get_artikel", response);
        this.setState({
          errArtikel: JSON.stringify(response),
        });
        this.setState({ loadingArtikel: false });
      });
  }



  expandMenu = () => {
    const temp = this.state.is_expand;
    this.setState({
      is_expand: !temp,
    });
  };

  render() {
    if (this.props.suksesBayar == true) {
      return <Redirect to={"/payment/" + this.state.kodeBayar} />;
    }

    var settings = {
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      variableWidth: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            //initialSlide: 2
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <>
        <Helmet>
          <title>
            {configClass.title} | {this.config.get_site_info().name}
          </title>
        </Helmet>

        <Row>
          <Col xs={24} sm={24} lg={18} xl={18}>
            <SliderAtas />
          </Col>
          <SliderPromo />
        </Row>

        <div className="card-panel">
          <div class="garis-panel"></div>
          <div className="konten-panel">
            {(this.props.isMobile == true &&
              <div className="blok-gap-ganjil">
                <Row className="heading" style={{ marginBottom: 10 }}>
                  <Col span={12} className="kiri">
                    Layanan
                  </Col>
                </Row>
                <Row>
                  <Col span={6} className="center">
                    <Link to="/wisata">
                      <button
                        className="btn-netral"
                      >
                        <img src={process.env.PUBLIC_URL + "/icon-wisata.svg"} /><br />
                        Wisata
                      </button>
                    </Link>
                  </Col>
                  <Col span={6} className="center">
                    <Link to="/event">
                      <button
                        className="btn-netral"
                      >
                        <img src={process.env.PUBLIC_URL + "/icon-event.png"} height={57} /><br />
                        Event
                      </button>
                    </Link>
                  </Col>
                  <Col span={6} className="center">
                    <Link to="/kuliner">
                      <button
                        className="btn-netral"
                      >
                        <img src={process.env.PUBLIC_URL + "/icon-kuliner.svg"} /><br />
                        Kuliner
                      </button>
                    </Link>
                  </Col>
                  <Col span={6} className="center">
                    <button
                      className="btn-netral"
                    >
                      <img src={process.env.PUBLIC_URL + "/icon-qr.svg"} /><br />
                      Transaksi
                    </button>
                  </Col>
                </Row>
              </div>
            )}
            <div className="blok-gap-netral">
              <Row justify="space-between">
                <Col xs={24} sm={24} lg={24} xl={24}>
                  <Fade bottom>
                    <Row className="heading">
                      <Col span={12} className="kiri">
                        Rekomendasi Plesiran
                      </Col>
                      <Col span={12} className="kanan">
                        <Link to="/rekomendasi-plesiran">
                          Lihat Selengkapnya
                          <ArrowRightOutlined style={{ marginLeft: 5 }} />
                        </Link>
                      </Col>
                    </Row>
                  </Fade>
                  <Row gutter={24}>
                    {(this.state.loadingWisataFavorit == true &&
                      [...Array(12)].map((x, i) => (
                        <Col xs={12} sm={8} lg={4} xl={4} className="item-produk">
                          <div className="panel-item-produk">
                            <div className="panel-gambar-produk">
                              <Skeleton
                                active
                                paragraph={false}
                              />
                            </div>
                            <div
                              className="item-produk-nama"
                              style={{
                                marginTop: 10
                              }}
                            >
                              <Skeleton
                                active
                                paragraph={false}
                                title={{
                                  width: "70%"
                                }}
                              />
                              <Skeleton
                                active
                                paragraph={false}
                                title={{
                                  width: "100%"
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      ))
                    )}
                    {(this.state.dataWisataFavorit != null && this.state.dataWisataFavorit.results.map((item, idx) => (
                      // {(this.state.dataWisataFavorit != null && this.state.dataWisataFavorit.map((item, idx) =>(
                      <Col xs={12} sm={8} lg={4} xl={4} className="item-produk">
                        <Fade bottom>
                          <Link to={"/detail-produk/" + item.slug}>
                            <div className="panel-item-produk">
                              <div className="panel-gambar-produk">
                                <LazyLoadImage
                                  className="gbr-produk-grid"
                                  key={"gbr-produk" + item.id}
                                  alt={item.nama}
                                  effect="blur"
                                  src={item.gambar[0] != undefined ? item.gambar[0].url : process.env.PUBLIC_URL + "/no_image.png"}
                                  width={"100%"}
                                // placeholder={
                                //   <Spin />
                                // }
                                // onClick={() => {
                                //   this.setState({
                                //     image_focus_url: row.payment_detail.icon,
                                //     visible_image_focus: true,
                                //     focus_title: "ICON"
                                //   })
                                // }}
                                // style={{cursor: "pointer"}}
                                />


                              </div>
                              <Row className="item-produk-jenis" justify="space-between">
                                <Col className="lokasi">
                                  {(item.kecamatan_nama != null &&
                                    <>
                                      <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: 5 }} />
                                      {item.kecamatan_nama}
                                    </>
                                  )}
                                </Col>
                                <Col>
                                  <button
                                    className="btn-jenis-produk"
                                  >
                                    {item.jenis == 1 && "Wisata"}
                                    {item.jenis == 7 && "Event"}
                                    {item.jenis == 3 && "Kuliner"}
                                  </button>
                                </Col>

                              </Row>
                              {/*
                            <div className="lokasi">
                              <FontAwesomeIcon icon={faMapMarkerAlt} />
                              {item.kecamatan_nama}
                            </div>
                          */}
                              <div className="item-produk-nama">
                                {item.nama}
                              </div>
                              <Row className="item-produk-rating">
                                <Col span={8} className="item-produk-rate">
                                  <img
                                    src={process.env.PUBLIC_URL + "/star.svg"}
                                    style={{ height: 10 }}
                                  />
                                  {item.rating}
                                </Col>
                                <Col span={8} className="item-produk-dilihat">
                                  <img
                                    src={process.env.PUBLIC_URL + "/dilihat.svg"}
                                    style={{ height: 14 }}
                                  />
                                  {item.page_views}
                                </Col>
                                <Col span={8} className="item-produk-disukai">
                                  <img
                                    src={process.env.PUBLIC_URL + "/like.svg"}
                                    style={{ height: 10 }}
                                  />
                                  {item.count_like}
                                </Col>
                              </Row>
                            </div>
                          </Link>
                        </Fade>
                      </Col>
                    )))}
                  </Row>
                </Col>
              </Row>
            </div>

            {(this.props.isMobile == true &&
              <SliderPromoMobile />
            )}

            <div className="blok-gap-netral">
              <Row className="heading" style={{ marginTop: 30 }}>
                <Col span={12} className="kiri">
                  Info Plesiran
                </Col>
                <Col span={12} className="kanan">
                  <Link to="/info">
                    Lihat Selengkapnya
                    <ArrowRightOutlined style={{ marginLeft: 5 }} />
                  </Link>
                </Col>
              </Row>
              {this.state.loadingArtikel &&
                <Skeleton active paragraph={true} />
              }
              <Row>
                {this.state.dataArtikel != null && [...Array(1)].map((x, i) => (

                  <Col md={{ span: 12 }} sm={{ span: 24 }} className="info-pin">
                    <img
                      src={this.state.dataArtikel.results[0].gambar} style={{ objectFit: "cover" }}
                      onError={(e) => {
                        e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + "/no_image.png"
                      }}
                    />
                    <div className="caption">
                      <div className="judul">
                        {this.state.dataArtikel.results[0].judul}
                      </div>
                      <div className="isi">
                        {this.config.stripTags(this.state.dataArtikel.results[0].content).substring(0, 260) + " ..."}
                        <br />
                        <Link to={"/info/detail/" + this.state.dataArtikel.results[0].id + "/" + this.config.getSlug(this.state.dataArtikel.results[0].judul)}>
                          <button
                            className="btn-selengkapnya"
                          >
                            selengkapnya
                            <ArrowRightOutlined style={{ marginLeft: 5 }} />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </Col>
                ))}

                <Col md={{ span: 12 }} sm={{ span: 24 }} className="info-kanan">
                  {this.state.dataArtikel != null && [...Array(4)].map((x, i) => (
                    i > 0 &&
                    this.state.dataArtikel.results[i] != undefined &&
                    <Row className="info-kanan-row">
                      <Col md={{ span: 10 }} sm={{ span: 24 }}>
                        <img
                          src={this.state.dataArtikel.results[i].gambar} style={{ objectFit: "cover" }}
                          onError={(e) => {
                            e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + "/no_image.png"
                          }}
                        />
                      </Col>
                      <Col md={{ span: 14 }} sm={{ span: 24 }} className="caption">
                        <div className="judul">
                          {this.state.dataArtikel.results[i].judul}
                        </div>
                        <div className="tgl">
                          {this.state.dataArtikel.results[i].update_at != null
                            ?
                            moment(this.state.dataArtikel.results[i].update_at).format("DD-MMM-YYYY HH:mm")
                            :
                            moment(this.state.dataArtikel.results[i].created_at).format("DD-MMM-YYYY HH:mm")
                          }
                        </div>
                        <div className="isi">
                          {this.config.stripTags(this.state.dataArtikel.results[i].content).substring(0, 120) + " ..."}
                          <br />
                          <Link to={"/info/detail/" + this.state.dataArtikel.results[i].id + "/" + this.config.getSlug(this.state.dataArtikel.results[i].judul)}>
                            <button
                              className="btn-selengkapnya"
                            >
                              selengkapnya
                              <ArrowRightOutlined style={{ marginLeft: 5 }} />
                            </button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </div>

            {/*<div className="blok-gap-netral available-on">
              <Row className="heading" justify="center">
                <Col span={24} className="teks-download">
                  Download Aplikasi Plesiran
                </Col>
                <Col>
                  <img src={process.env.PUBLIC_URL + "/gplay.png"} height={100} />
                </Col>
                <Col style={{display: "none"}}>
                  <img src={process.env.PUBLIC_URL + "/apstore.png"} height={100} />
                </Col>
              </Row>
            </div>*/}

          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(Beranda);
