import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  Skeleton,
  Card,
  Radio,
  Spin,
  Select,
  Tag,
  Menu,
  Popover,
  Collapse,
} from "antd";
import {
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  StarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ArrowRightOutlined,
  RightOutlined,
  QrcodeOutlined,
  BarcodeOutlined,
  CopyOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { faStar, faComment, faCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";

import ReactPlayer from "react-player";

import "antd/dist/antd.css";
import "../assets/styleFe.css";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

import ReactStars from "react-rating-stars-component";
import Fade from "react-reveal/Fade";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from "moment";
import "moment/locale/id";

import ReactGA from "react-ga";
ReactGA.pageview(window.location.pathname + window.location.search);

moment.locale("id");

const configClass = {
  apiUrl: {
    data: "transaksi/api/transaksi/",
    orders: "transaksi/api/orders/?transaksi="
  },
  // title: "Term & Conditions",
};

class RiwayatTransaksi extends PureComponent {
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      loading: false,
      data: null,
      err: null,

      loadingOrders: false,
      dataOrders: null,
      errOrders: null,
    };
  }

  componentDidMount() {
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    //  this.props.setConfig({
    //   isMoblie: true
    //  });
    // }
    window.scrollTo(0, 0);

    this.getData();
  }

  async getData() {
    this.setState({
      loading: true,
      err: null,
      data: null,
    });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.data + "?limit=1000", null, "login")
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            data: data,
          });
        } else {
          this.setState({
            err: JSON.stringify(data),
          });
        }
        this.setState({ loading: false });
      })
      .catch((response) => {
        console.log("gagal_get_term", response);
        this.setState({
          err: JSON.stringify(response),
        });
        this.setState({ loading: false });
      });
  }

  async getOrders(id) {
    this.setState({
      loadingOrders: true,
      errOrders: null,
      dataOrders: null,
    });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.orders + id, null, "login")
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          this.setState({
            dataOrders: data,
          });
        } else {
          this.setState({
            err: JSON.stringify(data),
          });
        }
        this.setState({ loadingOrders: false });
      })
      .catch((response) => {
        console.log("gagal_get_term", response);
        this.setState({
          err: JSON.stringify(response),
        });
        this.setState({ loadingOrders: false });
      });
  }

  render() {
    if (this.props.isLogin == false) {
      return <Redirect to={"/"} />;
    }
    return (
      <>
        <Helmet>
          <title>
            {"Riwayat Transaksi | " + this.config.get_site_info().name}
          </title>
        </Helmet>

        <div className="blok-gap-netral single-content">
          {this.state.loading && (
            <Row className="heading">
              <Col span={12} className="kiri">
                <Skeleton active paragraph={true} />
              </Col>
            </Row>
          )}
          {this.state.data != null && (
            <div className="tiketku-heading">
              Riwayat Transaksi Online
            </div>
          )}
          {this.state.data != null &&
            <Row
              gutter={[16, 16]}
            >
              {this.state.data.results.map((item, idx) => (
                <Col
                  sm={{ span: 12 }}
                >
                  <div
                    className="tiketku-row"
                    style={{
                      border: "1px solid #25BEF5",
                      // padding: 5,
                      borderRadius: 10,
                      // marginBottom: 10
                    }}
                  >
                    <div className="tiketku-header">
                      <div className="tiketku-kode">
                        {"Kode : " + item.kode}
                      </div>
                      <div className="tiketku-status">
                        {item.status == 1 && <Tag color="#FFA500">menunggu pembayaran</Tag>}
                        {item.status == 2 && <Tag color="#87d068">pembayaran valid</Tag>}
                        {item.status == 3 && <Tag color="#f50">pembayaran tidak valid</Tag>}
                        {item.status == 4 && <Tag color="#000">expired</Tag>}
                      </div>

                    </div>
                    <div className="tiketku-body">
                      <Row>
                        <Col sm={{ span: 8 }} span={24} className="titik-dua">
                          Tgl. Transaksi
                        </Col>
                        <Col sm={{ span: 16 }} span={24}>
                          {moment(item.created_at).format("LLLL")}
                        </Col>
                        {item.payment_log.expired_date != undefined &&
                          <>
                            <Col sm={{ span: 8 }} span={24} className="titik-dua">
                              Batas Akhir Pembayaran
                            </Col>
                            <Col sm={{ span: 16 }} span={24}>
                              {moment(
                                new Date(
                                  item.payment_log.expired_date
                                )).format("LLLL")}
                            </Col>
                          </>
                        }
                        <Col sm={{ span: 8 }} span={24} className="titik-dua">
                          Total
                        </Col>
                        <Col sm={{ span: 16 }} span={24}>
                          {item.payment_log.bill_payment != undefined
                            ? "Rp. " +
                            item.payment_log.bill_payment.amount.toLocaleString("ID")
                            :
                            "Rp. " + item.total_bayar.toLocaleString("ID")
                          }
                        </Col>
                      </Row>

                      <div className="tiketku-detail">
                        <CopyOutlined /> Detail Produk / Tiket
                      </div>

                      {item.details.map((itemDetail, idxDetail) => (
                        <>
                          <div
                            style={{
                              // // border: "1px solid #469AF8",
                              // borderRadius: 5,
                              // padding: 5,
                              // marginTop: 5,
                              fontSize: 10,
                              fontWeight: "bold",
                            }}
                          >
                            <BarcodeOutlined
                              style={{ marginRight: 10 }}
                            />
                            {itemDetail.quantity + " x " + itemDetail.produk_nama}
                          </div>
                        </>
                      ))}
                      {item.status == 1 &&
                        item.payment_detail.id != undefined && item.payment_log.bill_payment != undefined &&
                        <div className="petunjuk-pembayaran-row">
                          <Collapse
                            key={"petunjuk" + item.id}
                            // defaultActiveKey={[item.id + "1"]}
                          >
                            <Collapse.Panel
                              header="Tata Cara Pembayaran" 
                              key={item.id + "1"}
                            >
                              {item.payment_detail.code != "wallet_account"
                                ?
                                <>
                                  <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                                    <Col span={9}>ID Transaksi</Col>
                                    <Col span={15} style={{ textAlign: "right" }}>
                                      {item.payment_log.bill_payment.id}
                                      <div>
                                        <CopyToClipboard
                                          text={
                                            item.payment_log.bill_payment
                                              .id
                                          }
                                          onCopy={() => {
                                            Modal.success({
                                              content: "ID Transaksi berhasil di copy",
                                              zIndex: 10000,
                                            });
                                          }}
                                        >
                                          <Button
                                            type="primary"
                                            htmlType="button"
                                            size="small"
                                            ghost
                                          >
                                            <CopyOutlined />
                                            salin id transaksi
                                          </Button>
                                        </CopyToClipboard>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                                    <Col span={9}>Metode Pembayaran</Col>
                                    <Col span={15} style={{ textAlign: "right" }}>
                                      {item.payment_detail.code.replace(
                                        /_/g,
                                        " "
                                      )}
                                    </Col>
                                  </Row>
                                  <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                                    <Col span={9}>Bank/ E-Wallet</Col>
                                    <Col span={15} style={{ textAlign: "right" }}>
                                      <img
                                        src={item.payment_detail.icon}
                                        height={30}
                                      />
                                    </Col>
                                  </Row>
                                  <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                                    <Col span={9}>Nomor Virtual Account (VA)</Col>
                                    <Col span={15} style={{ textAlign: "right" }}>
                                      {
                                        item.payment_log.bill_payment
                                          .receiver_bank_account.account_number
                                      }
                                      <div>
                                        <CopyToClipboard
                                          text={
                                            item.payment_log.bill_payment
                                              .receiver_bank_account.account_number
                                          }
                                          onCopy={() => {
                                            Modal.success({
                                              content: "Nomor VA berhasil di copy",
                                              zIndex: 10000,
                                            });
                                          }}
                                        >
                                          <Button
                                            type="primary"
                                            htmlType="button"
                                            size="small"
                                            ghost
                                          >
                                            <CopyOutlined />
                                            salin kode VA
                                          </Button>
                                        </CopyToClipboard>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                                    <Col span={9}>Nominal Tagihan</Col>
                                    <Col span={15} style={{ textAlign: "right" }}>
                                      {item.payment_log.bill_payment.amount.toLocaleString(
                                        "ID"
                                      )}
                                      <div>
                                        <CopyToClipboard
                                          text={
                                            item.payment_log.bill_payment
                                              .amount
                                          }
                                          onCopy={() => {
                                            Modal.success({
                                              content: "nominal tagihan berhasil di copy",
                                              zIndex: 10000,
                                            });
                                          }}
                                        >
                                          <Button
                                            type="primary"
                                            htmlType="button"
                                            size="small"
                                            ghost
                                          >
                                            <CopyOutlined />
                                            salin nominal tagihan
                                          </Button>
                                        </CopyToClipboard>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                                    <Col span={9}>Bayar Sebelum</Col>
                                    <Col span={15} style={{ textAlign: "right" }}>
                                      {moment(
                                        new Date(
                                          item.payment_log.expired_date
                                        )
                                      ).format("LLLL")}
                                    </Col>
                                  </Row>
                                  <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                                    <Col span={9}>Status Pembayaran</Col>
                                    <Col span={15} style={{ textAlign: "right" }}>
                                      <div>
                                        {this.state.loadingRefresh ? (
                                          <Spin />
                                        ) : (
                                          <>
                                            {item.payment_status == "1" ? (
                                              <Tag color="orange">
                                                Menunggu Pembayaran
                                              </Tag>
                                            ) : (
                                              item.payment_status == "2" ?
                                                <Tag color="green">
                                                  Pembayaran Valid
                                                </Tag>
                                                :
                                                <Tag color="blue">
                                                  {item.payment_status}
                                                </Tag>

                                            )}
                                          </>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row gutter={[16, 16]} style={{ borderBottom: "1px solid #ccc" }}>
                                    <Col span={24} style={{ marginBottom: 10 }}>
                                      Petunjuk Tata Cara Pembayaran
                                    </Col>
                                    <Col span={24}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item.payment_detail.catatan,
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </>
                                :
                                <a href={item.payment_log.payment_url} target="_blank">
                                  <Button
                                    htmlType="button"
                                    type="primary"
                                    className="btn-instruksi-pembayaran"
                                    danger
                                  >
                                    <QuestionCircleOutlined />
                                    instruksi pembayaran
                                  </Button>
                                </a>
                              }
                            </Collapse.Panel>
                          </Collapse>
                        </div>
                      }
                      {item.status == 2
                        &&

                        <Button
                          type="text"
                          className="tiketku-btn-etiket"
                          onClick={() => {
                            this.setState({
                              modal_detail: true
                            }, () => {
                              this.getOrders(item.id)
                            })
                          }}
                        >
                          <QrcodeOutlined
                            className="icon"
                          />
                          <br />
                          lihat E-Tiket
                        </Button>
                      }
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          }
        </div>

        <Modal
          title="E-TIKET"
          visible={this.state.modal_detail}
          onCancel={() => {
            this.setState({ modal_detail: false });
          }}
          footer={[]}
          zIndex={10000}
          style={{
            top: 100,
          }}
        // width={"40%"}
        >
          {this.state.loadingOrders && (
            <Row className="heading">
              <Col span={12} className="kiri">
                <Skeleton active paragraph={true} />
              </Col>
            </Row>
          )}

          <Row
            gutter={[16, 16]}
          >

            {this.state.dataOrders != null && this.state.dataOrders.results.map((item, idx) => (
              <Col
                span={24}
              >
                <div
                  className="tiketku-row"
                  style={{
                    border: "1px solid #25BEF5",
                    // padding: 5,
                    borderRadius: 10,
                    // marginBottom: 10
                  }}
                >
                  <div className="tiketku-header">
                    <div className="tiketku-kode">
                      {item.usaha_nama}
                    </div>
                    <div className="tiketku-status">
                      {item.approved_status == 1 && <Tag color="#FFA500">menunggu konfirmasi mitra</Tag>}
                      {item.approved_status == 2 && <Tag color="#87d068">diterima oleh mitra</Tag>}
                      {item.approved_status == 3 && <Tag color="#f50">ditolak oleh mitra</Tag>}
                    </div>
                  </div>
                  <div className="tiketku-body">
                    {item.approved_status == 2 &&
                      <div style={{ textAlign: "center" }}>
                        <QRCode
                          value={item.qr}
                          size={200}
                        />
                      </div>
                    }

                    {item.approved_status == 1 &&
                      <Alert
                        showIcon
                        style={{
                          marginBottom: 10,
                        }}
                        message="E-Tiket Pending"
                        description={
                          <div>
                            E-Tiket akan didapatkan setelah mitra mengkonfirmasi transaksi
                          </div>
                        }
                        type="warning"
                      />
                    }
                    <div className="tiketku-detail">
                      <CopyOutlined /> Detail Produk / Tiket
                    </div>


                    {item.details.map((itemDetail, idxDetail) => (
                      <Row style={{ padding: 5, borderBottom: "1px solid #ccc" }}>
                        <Col span={4}><img src={itemDetail.gambar[0].url} height={30} /></Col>
                        <Col span={20}>
                          {itemDetail.quantity + " x " + itemDetail.produk_nama}<br />
                          <span style={{ fontSize: 12, fontStyle: "italic" }}>

                            {itemDetail.checkin_at != null
                              ?
                              <div className="tiketku-sudah-checkin">
                                <CheckCircleOutlined className="icon-checkin" />
                                {" sudah checkin : " + moment(itemDetail.checkin_at).format("LLLL")}
                              </div>
                              :
                              ""
                            }
                            {item.approved_status == 1 && "E-Tiket akan didapatkan setelah mitra mengkonfirmasi"}
                          </span>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>
              </Col>
            ))}
          </Row>

        </Modal>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(RiwayatTransaksi);
