import React, { PureComponent } from "react";
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Tabs,
  Skeleton,
  Card,
  Radio,
  Spin,
  Select,
  Tag,
  Menu,
  Popover,
  Result
} from "antd";
import { 
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  StarOutlined, RightOutlined, CheckCircleOutlined, CloseCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";

import { faStar, faComment, faCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import axios from "axios";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";

import ReactPlayer from 'react-player'

import "antd/dist/antd.css";
import "../assets/styleFe.css?v2";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import "react-awesome-slider/dist/styles.css";

import ReactStars from "react-rating-stars-component";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import moment from 'moment';
import 'moment/locale/id'

import Header from '../components/Header.jsx';
import Footer from '../components/Footer.jsx';

import Beranda from '../views/Beranda.jsx';
import Wisata from '../views/Wisata.jsx';
import Kuliner from '../views/Kuliner.jsx';
import Pencarian from '../views/Pencarian.jsx';
import TentangPlesiran from '../views/TentangPlesiran.jsx';
import TermConditions from '../views/TermConditions.jsx';

import DetailProduk from "../layouts/DetailProduk.jsx";
import Payment from "../layouts/Payment.jsx";
import Etiket from "../layouts/Etiket.jsx";

import DetailArtikel from "../views/DetailArtikel.jsx";
import RiwayatTransaksi from "../views/RiwayatTransaksi.jsx";
import RiwayatTransaksiDetail from "../views/RiwayatTransaksiDetail.jsx";

import ReactGA from 'react-ga';
import Event from "../views/Event.jsx";
import Info from "../views/Info.jsx";
import Promo from "../views/Promo.jsx";
import Semua from "../views/Semua.jsx";
ReactGA.initialize('G-Z9G0ZD4K8T');


moment.locale('id');

const configClass = {
  apiUrl: {
    
  },
};

const AutoplaySlider = withAutoplay(AwesomeSlider);

class MainLayout extends PureComponent {
  formRef = React.createRef();
  config;

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      
    };
  }

  componentDidMount() {
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    //  this.props.setConfig({
    //   isMoblie: true
    //  });
    // }
  }

  render() {

    return (
      <div className="main-home">
        <Header />
          <Switch>
              <Route exact path="/" component={Beranda} />
              <Route exact path="/beranda" component={Beranda} />
              <Route exact path="/rekomendasi-plesiran" component={Semua} />
              <Route exact path="/wisata" component={Wisata} />
              <Route exact path="/event" component={Event} />
              <Route exact path="/kuliner" component={Kuliner} />
              <Route exact path="/pencarian" component={Pencarian} />
              <Route exact path="/tentang-plesiran" component={TentangPlesiran} />
              <Route exact path="/term-conditions" component={TermConditions} />

              <Route path="/detail-produk/:id?" component={DetailProduk} />
              <Route path="/payment/:id?" component={Payment} />
              <Route path="/e-tiket/:id?" component={Etiket} />

              <Route path="/info" exact component={Info} />
              <Route path="/info/detail/:id/:slug" component={DetailArtikel} />

              <Route path="/promo" exact component={Promo} />
              <Route path="/promo/detail/:id/:slug" component={DetailArtikel} />

              <Route path="/tiketku" exact component={RiwayatTransaksi} />
              {/* <Route path="/tiketku/:id" exact component={RiwayatTransaksiDetail} /> */}

              <Route path="" render={
                  () => 
                      <Result
                          style={{marginTop: 75}}
                          status="404"
                          title={
                            <div>
                            404
                            </div>
                          }
                          subTitle={
                            <div>
                              <h1>Maaf, halaman tidak ditemukan</h1><br />
                              <img src={process.env.PUBLIC_URL + "/logo.png"} height="50" /><br /><br />
                              <Link to="/">
                                <Button type="primary">
                                  kembali ke halaman utama
                                </Button>
                              </Link>
                            </div>
                          }
                      />
              } />
          </Switch>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(MainLayout);
